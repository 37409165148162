import React, { useEffect, useState } from "react";

import { CircularProgress, CircularProgressLabel, Td, useToast } from "@chakra-ui/react";
import axios, { isAxiosError } from "axios";
import { PREURL, UPLOAD } from "config/config";

interface Props {
  file: File;
  group: string;
  setting: string;
  quality: boolean[];
  onChange: (p: boolean) => void;
}

const Item = ({ file, group, setting, quality, onChange }: Props): React.ReactElement => {
  const toast = useToast();

  const [progress, setProgress] = useState<number>(0);

  const uploadRequest = async (payload: File) => {
    try {
      // req s3 url
      const respURL = await axios.post(PREURL.CREATE, {
        UploadType: "content",
        GroupName: group,
        SettingName: setting,
        FileName: payload.name,
      });

      const signedUrl = respURL.data.SignedUrl;

      // upload to s3
      const respS3 = await axios.put(signedUrl, payload, {
        onUploadProgress: (e) => {
          const current = parseInt(((e.progress || 0) * 100).toString());
          setProgress(current);
        },
      });

      // req upload request

      const downloadModes: string[] = [];

      if (quality[0]) downloadModes.push("hq");
      if (quality[1]) downloadModes.push("sq");
      if (quality[2]) downloadModes.push("lq");

      const respUpload = await axios.post(UPLOAD.CREATE, {
        GroupName: group,
        TargetName: setting,
        FileName: `${group}/${setting}/${payload.name}`,
        Quality: downloadModes,
      });

      onChange(true);
      toast({
        title: `${payload.name} 워터마크 삽입 요청 완료`,
        description: `Status Page에서 확인해주세요. ${JSON.stringify(respUpload.data)}`,
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } catch (err) {
      setProgress(-1);
      onChange(false);

      if (isAxiosError(err)) {
        const descrption = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;
        toast({
          title: "워터마크 삽입 요청 실패",
          description: descrption,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "워터마크 삽입 요청 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  useEffect(() => {
    uploadRequest(file);
  }, [file]);

  return (
    <>
      <Td>{file.name}</Td>
      <Td textAlign={"center"}>
        <CircularProgress value={progress} color="green.400">
          <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
        </CircularProgress>
      </Td>
    </>
  );
};

export default Item;
