import React from "react";

import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  VStack,
  Divider,
  Card,
  CardBody,
  CardHeader,
  Heading,
} from "@chakra-ui/react";

const dateFormat = (dateString: string): string => {
  const date = new Date(dateString);
  let result = "";
  result = date.toLocaleString();
  return result;
};

const StatusStat = ({ status }: { status: Status }): React.ReactElement => {
  return (
    <Card minWidth={"800px"} width={"80vw"} justify={"left"}>
      <CardHeader>
        <Heading size={"md"} textAlign={"left"}>
          Status Info
        </Heading>
      </CardHeader>
      <CardBody>
        <Box>
          <VStack divider={<Divider />}>
            <StatGroup width={"100%"} textAlign={"left"}>
              <Stat>
                <StatLabel>Status Id</StatLabel>
                <StatNumber>{status.StatusId}</StatNumber>
                <StatHelpText>작업 아이디</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>File Name</StatLabel>
                <StatNumber>{status.FileName.split("/").slice(-1)}</StatNumber>
                <StatHelpText>작업한 파일의 이름</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Setting</StatLabel>
                <StatNumber>{status.TargetName}</StatNumber>
                <StatHelpText>작업에 적용된 세팅 아이디</StatHelpText>
              </Stat>
            </StatGroup>
            <StatGroup width={"100%"} textAlign={"left"}>
              <Stat>
                <StatLabel>Start Date</StatLabel>
                <StatNumber>{dateFormat(status.StartDate)}</StatNumber>
                <StatHelpText>작업이 시작된 시간</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Update Date</StatLabel>
                <StatNumber>{dateFormat(status.UpdatedAt)}</StatNumber>
                <StatHelpText>작업 상태의 마지막 변경시간</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Status</StatLabel>
                <StatNumber>{status.Status}</StatNumber>
                <StatHelpText>현재 작업 상황</StatHelpText>
              </Stat>
            </StatGroup>
            {status.Status === "Error" && (
              <StatGroup width={"100%"} textAlign={"left"}>
                <Stat>
                  <StatLabel>Error Message</StatLabel>
                  <StatNumber whiteSpace={"pre-line"}>{status.Error}</StatNumber>
                  <StatHelpText>에러 메세지</StatHelpText>
                </Stat>
              </StatGroup>
            )}
          </VStack>
        </Box>
      </CardBody>
    </Card>
  );
};

export default StatusStat;
