import React, { useEffect, useState } from "react";

import {
  Box,
  Center,
  Flex,
  Heading,
  Card,
  Button,
  useDisclosure,
  CardBody,
  CardHeader,
  Spacer,
  useToast,
} from "@chakra-ui/react";

import { RiPlayListAddLine } from "react-icons/ri";
import { EmptySetting } from "model/setting";
import Table from "components/settings/Table";
import { useGroup } from "hooks/useGroup";
import UpdateModal from "components/settings/Modal/Update";
import axios, { isAxiosError } from "axios";
import { SETTING } from "config/config";

const Settings = (): React.ReactElement => {
  const toast = useToast();
  const { isOpen: createModalStatus, onOpen: createModalOpen, onClose: createModalClose } = useDisclosure();
  const { selected: group } = useGroup();
  const [settings, setSettings] = useState<Setting[]>([]);

  const updateSettingList = async () => {
    try {
      const resp = await axios.get(`${SETTING.SEARCH_LIST}/${encodeURIComponent(group)}`);
      setSettings([...resp.data.EmbedTargets]);

      toast({
        title: "설정 조회 성공",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "설정 조회 실패",
          description: err.response?.data.message,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "설정 조회 실패",
          description: `${err}`,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    updateSettingList();
  }, [group]);

  return (
    <Center>
      <Card minWidth={"900px"} width={"70vw"}>
        <CardHeader>
          <Flex width={"100%"}>
            <Heading size={"md"}>Watermark Setting</Heading>
            <Spacer />
            <Spacer />
            <Center>
              {group !== "" && (
                <>
                  <Button
                    aria-label={"add setting"}
                    leftIcon={<RiPlayListAddLine />}
                    colorScheme={"teal"}
                    onClick={createModalOpen}
                  >
                    ADD
                  </Button>
                  <UpdateModal
                    modalType={"Create"}
                    group={group}
                    status={createModalStatus}
                    onClose={() => {
                      createModalClose();
                      updateSettingList();
                    }}
                    setting={EmptySetting}
                  />
                </>
              )}
            </Center>
          </Flex>
        </CardHeader>
        <CardBody>
          <Center width={"100%"} mt={"20px"}>
            <Box overflow={"scroll"}>
              <Table settings={settings} callback={updateSettingList} />
            </Box>
          </Center>
        </CardBody>
      </Card>
    </Center>
  );
};

export default Settings;
