import React from "react";

import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatGroup,
  VStack,
  Divider,
  Link as Portal,
} from "@chakra-ui/react";
import { LINK } from "config/config";

const LinkStat = ({ link }: { link: Link }): React.ReactElement => {
  return (
    <Box>
      <VStack divider={<Divider />}>
        <StatGroup width={"100%"} textAlign={"left"}>
          <Stat>
            <StatLabel>Limit</StatLabel>
            <StatNumber>{link.Count === -1 ? "∞" : link.Count}</StatNumber>
            <StatHelpText>다운로드 제한 횟수</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Deadline</StatLabel>
            <StatNumber>{link.Expire ? new Date(link.Expire).toLocaleDateString() : "∞"}</StatNumber>
            <StatHelpText>링크의 유효기간</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Auto Delete</StatLabel>
            <StatNumber>{link.AutoDelete ? "true" : "false"}</StatNumber>
            <StatHelpText>자동 삭제 여부</StatHelpText>
          </Stat>
        </StatGroup>
        <StatGroup width={"100%"} textAlign={"left"}>
          <Stat>
            <StatLabel>URL</StatLabel>
            <StatNumber>
              <Portal
                href={LINK.PREFIX + encodeURIComponent(link.Link.substring(LINK.PREFIX.length))}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.Link}
              </Portal>
            </StatNumber>
            <StatHelpText>미디어 링크</StatHelpText>
          </Stat>
        </StatGroup>
      </VStack>
    </Box>
  );
};

export default LinkStat;
