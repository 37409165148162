import React, { ChangeEvent } from "react";
import { Box, Input, Icon, Center, Text, Flex } from "@chakra-ui/react";
import { BsCloudUpload } from "react-icons/bs";

type Props = {
  width: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  multiple: boolean;
};

const Dropzone = ({ width, onChange, multiple = true }: Props): React.ReactElement => {
  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  return (
    <Box position={"relative"} w={width} minH={"200px"} borderWidth="1px" borderRadius="lg">
      <Flex w={"100%"} h={"100%"} position={"absolute"} alignItems={"center"} justifyContent={"center"} direction={"column"}>
        <Center>
          <Icon as={BsCloudUpload} boxSize={"100px"}></Icon>
        </Center>
        <Center>
          <Text fontSize={"md"}>Drop files here or click to Upload</Text>
        </Center>
      </Flex>
      <Input
        type={"file"}
        onClick={onInputClick}
        onChange={onChange}
        multiple={multiple}
        w={"100%"}
        h={"100%"}
        opacity={"0"}
        padding={"0"}
        left={"0"}
        top={"0"}
        position={"absolute"}
        cursor={"pointer"}
        zIndex={9999}
      ></Input>
    </Box>
  );
};

export default Dropzone;
