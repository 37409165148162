import React, { useEffect, useState } from "react";
import {
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Item from "./UploadItem";
import { useGroup } from "hooks/useGroup";

interface UploadModalProps {
  status: boolean;
  files: File[];
  setting: string;
  qualities: Map<string, boolean[]>;
  onClose: () => void;
}

const UploadModal = ({ status, files, setting, qualities, onClose }: UploadModalProps): React.ReactElement => {
  const { selected: group } = useGroup();
  const [endList, setEndList] = useState<boolean[]>([]);

  useEffect(() => {
    setEndList([]);
  }, [status]);

  useEffect(() => {
    if (endList.length === files.length) {
      onClose();
    }
  }, [endList]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={status} onClose={onClose} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack>
            <TableContainer width={"100%"}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <Center>파일 이름</Center>
                    </Th>
                    <Th>
                      <Center>진행도</Center>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {files
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((val, key) => (
                      <Tr key={key}>
                        <Item
                          file={val}
                          group={group}
                          setting={setting}
                          quality={qualities.get(val.name) || [false, false, false]}
                          onChange={(p) => {
                            endList.push(p);
                            setEndList([...endList]);
                          }}
                        />
                      </Tr>
                    ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Td colSpan={2}>
                      <Center>
                        <Flex align={"center"} color={"gray"}>
                          <AiOutlineInfoCircle />
                          <Text size={"sm"} ml={"5px"}>
                            파일의 크기에 따라 업로드에 시간이 소요될 수 있습니다. 업로드가 완료될 때까지 창을 떠나지
                            말아주세요.
                          </Text>
                        </Flex>
                      </Center>
                    </Td>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;
