import React from "react";

import { ModalProps } from "model/setting";

import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";

import axios, { isAxiosError } from "axios";
import { SETTING } from "config/config";

const DeleteModal = ({ status, group, onClose, setting }: ModalProps): React.ReactElement => {
  const toast = useToast();

  const deleteSetting = async () => {
    try {
      const _ = await axios.delete(`${SETTING.DELETE}/${encodeURIComponent(group)}/${encodeURIComponent(setting.Name)}`);

      onClose();
      toast({
        title: "설정 삭제 완료",
        status: "success",
        isClosable: true,
        duration: 10000,
      });
    } catch (err) {
      onClose();
      if (isAxiosError(err)) {
        toast({
          title: "설정 삭제 실패",
          description: err.response?.data.message,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={status} onClose={onClose} size={"2xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{setting.Name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} justifyContent={"center"}>
          <Text>{setting.Name}을 삭제합니다.</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => deleteSetting()} colorScheme={"blue"} mr={3}>
            Apply
          </Button>
          <Button onClick={onClose} colorScheme={"red"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
