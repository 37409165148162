import React from "react";

import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { LINK } from "config/config";
import axios, { isAxiosError } from "axios";

const DeleteModal = ({
  status,
  link,
  sourceFile,
  onClose,
}: {
  status: boolean;
  link: Link;
  sourceFile: string;
  onClose: () => void;
}): React.ReactElement => {
  const toast = useToast();

  /*========================================================================================================================*/

  const requestLinkDelete = async () => {
    try {
      const reqBody = { AccessToken: "", S3FilePath: [sourceFile], LinkSeq: link.LinkSeq };

      const deleteResp = await axios.delete(`${LINK.DLELTE}`, { data: { ...reqBody } });

      onClose();
      toast({
        title: "링크 삭제 완료",
        status: "success",
        isClosable: true,
        duration: 10000,
      });
    } catch (err) {
      onClose();

      if (isAxiosError(err)) {
        toast({
          title: "링크 삭제 실패",
          description: err.response?.data.message,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  /*========================================================================================================================*/

  return (
    <Modal closeOnOverlayClick={false} isOpen={status} onClose={onClose} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} justifyContent={"left"}>
          <Text>{`${link.LinkSeq}번 링크를 삭제합니다. 정말로 삭제하시겠습니까?`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => requestLinkDelete()} colorScheme={"blue"} mr={3}>
            Apply (링크 삭제)
          </Button>
          <Button onClick={onClose} colorScheme={"red"}>
            Cancel (요청 취소)
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
