import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Spacer, useSteps, VStack, Text, useToast } from "@chakra-ui/react";

import Indicator from "components/upload/Indicator";
import Memo from "components/upload/Memo";

import SettingStage from "components/upload/Stage/SettingStage";
import FileStage from "components/upload/Stage/FileStage";
import UploadStage from "components/upload/Stage/UploadStage";

import { useGroup } from "hooks/useGroup";
import { SETTING } from "config/config";
import axios, { isAxiosError } from "axios";

const UploadStep = [
  { title: "First", description: "Select Setting" },
  { title: "Second", description: "Select Files" },
  { title: "Third", description: "Upload Files" },
];

const Upload = (): React.ReactElement => {
  const toast = useToast();

  const { selected: group } = useGroup();
  const [nextDisable, setNextDisable] = useState<boolean>(true);
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: UploadStep.length });
  const [uploadForm, setUploadForm] = useState<UploadForm>({ group: group, setting: undefined, files: undefined });
  const [settings, setSettings] = useState<Setting[]>([]);

  /*================================ Stage ==============================*/

  const prev = () => {
    switch (activeStep) {
      case 1:
        {
          let newForm: UploadForm = { group: uploadForm.group, setting: undefined, files: undefined };
          setUploadForm(newForm);
          setActiveStep(0);
          setNextDisable(true);
        }
        break;
      case 2:
        {
          let newForm: UploadForm = { group: uploadForm.group, setting: uploadForm.setting, files: undefined };
          setUploadForm(newForm);
          setActiveStep(1);
          setNextDisable(true);
        }
        break;
      default:
        break;
    }
  };

  const next = () => {
    setNextDisable(true);
    setActiveStep(Math.min(activeStep + 1, UploadStep.length - 1));
  };

  /*================================ Upload Form ==============================*/

  const updateSettings = async () => {
    try {
      const resp = await axios.get(`${SETTING.SEARCH_LIST}/${encodeURIComponent(group)}`);
      setSettings(resp.data.EmbedTargets);

      toast({
        title: "설정 조회 성공",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "설정 조회 실패",
          description: err.response?.data.message,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "설정 조회 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  useEffect(() => {
    let newForm: UploadForm = { group: group, setting: undefined, files: undefined };
    updateSettings();
    setUploadForm(newForm);
    setActiveStep(0);
    setNextDisable(true);
  }, [group, setActiveStep]);

  const updateSetting = (value: Setting) => {
    let newSetting: Setting = JSON.parse(JSON.stringify(value));
    let newForm: UploadForm = { group: uploadForm.group, setting: newSetting, files: undefined };
    setUploadForm(newForm);

    if (value !== undefined) {
      setNextDisable(false);
    }
  };

  const updateFiles = (value: File[]) => {
    let newForm: UploadForm = { group: uploadForm.group, setting: uploadForm.setting, files: value };
    setUploadForm(newForm);

    if (value !== undefined) {
      setNextDisable(value.length === 0);
    }
  };

  return (
    <Center mt={"20px"}>
      <VStack spacing={10}>
        <Center width={"100vw"}>
          <Indicator activeStep={activeStep} steps={UploadStep}></Indicator>
        </Center>
        <Memo group={uploadForm.group} setting={uploadForm.setting} files={uploadForm.files} />
        <Box width={"100vw"} height={"500px"}>
          {activeStep === 0 && (
            <SettingStage selected={uploadForm.setting?.Name} onChange={updateSetting} settings={settings} />
          )}
          {activeStep === 1 && (
            <FileStage fileType={uploadForm.setting?.TargetType || "unknownType"} onChange={updateFiles} />
          )}
          {activeStep === 2 && <UploadStage form={uploadForm} />}
        </Box>
        <Center width={"100vw"}>
          <Flex width={"70vw"}>
            <Button onClick={() => prev()}>Prev step</Button>
            <Spacer />
            <Text>
              {activeStep + 1} / {UploadStep.length}
            </Text>
            <Spacer />
            <Button isDisabled={nextDisable} onClick={() => next()}>
              Next step
            </Button>
          </Flex>
        </Center>
      </VStack>
    </Center>
  );
};

export default Upload;
