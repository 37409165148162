import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  VStack,
  Heading,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  TagCloseButton,
  Select,
  useToast,
  Center,
} from "@chakra-ui/react";

import { MdAdd } from "react-icons/md";
import { useGroup } from "hooks/useGroup";
import axios, { isAxiosError } from "axios";
import { GROUP, USER } from "config/config";

const EditModal = ({ user, callback }: { user: User; callback: Function }): React.ReactElement => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isRoot } = useGroup();
  const [groups, setGroups] = useState<string[]>([]);
  const [newUser, setNewUser] = useState<User>({ ...user });

  const closeModal = () => {
    setNewUser({ ...user });
    onClose();
  };

  const getGroups = async () => {
    try {
      const resp = await axios.get(GROUP.SEARCH_LIST);
      setGroups(resp.data.Groups);
    } catch (err) {
      onClose();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;

        toast({
          title: "그룹 조회 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "그룹 조회 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  const requestUpdateUser = async () => {
    try {
      const reqBody = {
        UserName: newUser.UserName,
        Groups: [...newUser.Groups],
      };

      const _ = await axios.post(USER.UPDATE, reqBody);

      toast({
        title: "사용자 갱신 완료",
        status: "success",
        duration: 10000,
        isClosable: true,
      });

      callback();
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "사용자 갱신 실패",
          description: err.response?.data.message,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setNewUser({ ...user });
      getGroups();
    }
  }, [isOpen]);

  return (
    <>
      <Center>
        <Button onClick={onOpen}>Edit</Button>
      </Center>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User({`${newUser.UserName}`}) Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align={"left"}>
              <Heading size={"sm"}>{newUser.UserName}</Heading>
              <Heading size={"sm"}>Groups</Heading>
              <Wrap>
                {isRoot && newUser.Groups.includes("Admin") && (
                  <WrapItem>
                    <Tag>
                      <TagLabel>{"Admin"}</TagLabel>
                      <TagCloseButton
                        onClick={(_) =>
                          setNewUser({
                            ...newUser,
                            Groups: newUser.Groups.filter((val, _) => val !== "Admin"),
                          })
                        }
                      />
                    </Tag>
                  </WrapItem>
                )}
                {newUser.Groups.filter((value, _) => value !== "Admin").map((value, key) => (
                  <WrapItem key={key}>
                    <Tag>
                      <TagLabel>{value}</TagLabel>
                      <TagCloseButton
                        onClick={(_) =>
                          setNewUser({
                            ...newUser,
                            Groups: newUser.Groups.filter((val, _) => val !== value),
                          })
                        }
                      />
                    </Tag>
                  </WrapItem>
                ))}
                <WrapItem>
                  <Tag>
                    <Select
                      icon={<MdAdd />}
                      placeholder={"New"}
                      h={"100%"}
                      value={""}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setNewUser({
                            ...newUser,
                            Groups: [...newUser.Groups, e.target.value],
                          });
                        }
                      }}
                    >
                      {isRoot && !newUser.Groups.includes("Admin") && <option value={"Admin"}>{"Admin"}</option>}
                      {groups
                        .filter((value, _) => !newUser.Groups.includes(value))
                        .filter((value, _) => value !== "Admin" && value !== "WaitGroup" && value !== "ApiGroup")
                        .sort((l, r) => {
                          return l.localeCompare(r);
                        })
                        .map((value, key) => (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        ))}
                    </Select>
                  </Tag>
                </WrapItem>
              </Wrap>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                requestUpdateUser();
                onClose();
              }}
            >
              Apply
            </Button>
            <Button colorScheme="red" onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditModal;
