import React from "react";

import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";

const Indicator = ({ activeStep, steps }: { activeStep: number; steps: Stage[] }): React.ReactElement => {
  return (
    <Stepper
      index={activeStep}
      size={"lg"}
      width={"70vw"}
      border={"2px"}
      borderRadius={"lg"}
      borderColor={"gray.100"}
      padding={"20px"}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>

          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default Indicator;
