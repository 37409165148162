import React from "react";
import { Link } from "react-router-dom";

import { Button, Spinner } from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";

const Stat = ({ status, statusID }: { status: STATUS_ENUM; statusID: number }): React.ReactElement => {
  switch (status) {
    case "Complete":
      return (
        <Button
          colorScheme={"green"}
          variant={"outline"}
          rightIcon={<ArrowForwardIcon />}
          as={Link}
          to={`/info/${statusID}`}
        >
          {status}
        </Button>
      );
    case "Error":
      return (
        <Button colorScheme={"red"} variant={"outline"} rightIcon={<ArrowForwardIcon />} as={Link} to={`/info/${statusID}`}>
          {status}
        </Button>
      );
    default:
      return (
        <Button
          colorScheme={"yellow"}
          variant={"outline"}
          leftIcon={<Spinner thickness="2px" speed="0.65s" emptyColor="gray.200" color="yellow.400" size="sm" />}
          rightIcon={<ArrowForwardIcon />}
          as={Link}
          to={`/info/${statusID}`}
        >
          {status}
        </Button>
      );
  }
};

export default Stat;
