import React, { useState, ChangeEvent, useEffect } from "react";

import { ModalProps, EmptySetting } from "model/setting";

import template0 from "assets/img/template0.png";
import template1 from "assets/img/template1.png";
import template2 from "assets/img/template2.png";
import template3 from "assets/img/template3.png";

import Dropzone from "components/common/Dropzone";

import {
  Box,
  Center,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Checkbox,
  Flex,
  RadioGroup,
  Radio,
  Input,
  List,
  ListItem,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Highlight,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";

import axios, { isAxiosError } from "axios";
import { PREURL, SETTING } from "config/config";

const nameCheck = (name: string): boolean => {
  var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  var rg2 = /^\./; // cannot start with dot (.)
  var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  var rg4 = /%/;

  return rg1.test(name) && !rg2.test(name) && !rg3.test(name) && !rg4.test(name);
};

const typeToNumber = (type: "audio" | "image" | "video"): number => {
  switch (type) {
    case "image":
      return 0;
    case "audio":
      return 1;
    case "video":
      return 2;
  }
};

const deepcopy = (obj: Setting | undefined): Setting => {
  return JSON.parse(JSON.stringify(obj));
};

const UpdateModal = ({ modalType, group, status, setting, onClose }: ModalProps): React.ReactElement => {
  const toast = useToast();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  /*================================ Setting State ==============================*/

  // deep copy
  const [editedSetting, setEditedSetting] = useState<Setting>(deepcopy(EmptySetting));

  const [imageVisible, setImageVisible] = useState<boolean>(
    setting?.EmbedImage.VisibleFile || setting?.EmbedImage.VisibleText,
  );
  const [videoVisible, setVideoVisible] = useState<boolean>(
    setting?.EmbedVideo.VisibleFile || setting?.EmbedVideo.VisibleText,
  );
  const [videoAudible, setVideoAudible] = useState<boolean>(
    setting?.EmbedVideo.AudibleFile || setting?.EmbedVideo.AudibleTts,
  );
  const [audioAudible, setAudioAudible] = useState<boolean>(
    setting?.EmbedAudio.AudibleFile || setting?.EmbedAudio.AudibleTts,
  );

  useEffect(() => {
    if (status) {
      console.log("update");
      console.log(setting);
      setEditedSetting(deepcopy(setting));
      setImageVisible(setting?.EmbedImage.VisibleFile || setting?.EmbedImage.VisibleText);
      setVideoVisible(setting?.EmbedVideo.VisibleFile || setting?.EmbedVideo.VisibleText);
      setVideoAudible(setting?.EmbedVideo.AudibleFile || setting?.EmbedVideo.AudibleTts);
      setAudioAudible(setting?.EmbedAudio.AudibleFile || setting?.EmbedAudio.AudibleTts);
    }
  }, [setting, status]);

  const updateSettingName = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.Name = value;
    setEditedSetting(newSetting);
  };

  const updateSettingType = (index: number) => {
    let newSetting: Setting = deepcopy(editedSetting);

    switch (index) {
      case 0:
        newSetting.TargetType = "image";
        break;
      case 1:
        newSetting.TargetType = "audio";
        break;
      case 2:
        newSetting.TargetType = "video";
        break;
    }

    setEditedSetting(newSetting);
  };

  /*================================ File State ==============================*/
  const [uploadImageFile, setUploadImageFile] = useState<File | undefined>(undefined);
  const [uploadAudioFile, setUploadAudioFile] = useState<File | undefined>(undefined);

  const updateImageUploadFile = (e: ChangeEvent<HTMLInputElement>, callback: (path: string) => void) => {
    if (!e.target.files) {
      toast({
        title: "File select failed",
        description: "파일을 불러오지 못했습니다.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (!nameCheck(e.target.files[0].name)) {
      toast({
        title: "File name error",
        description: "파일 이름으로 사용할 수 없는 문자가 사용되었습니다.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (!e.target.files[0].type.startsWith("image")) {
      toast({
        title: "File type error",
        description: "파일 형식이 잘못되었습니다. (이미지가 아닙니다.)",
        status: "error",
        isClosable: true,
      });
      return;
    }
    setUploadImageFile(e.target.files[0]);
    callback(e.target.files[0].name);
  };

  const updateAudioUploadFile = (e: ChangeEvent<HTMLInputElement>, callback: (path: string) => void) => {
    if (!e.target.files) {
      toast({
        title: "File select failed",
        description: "파일을 불러오지 못했습니다.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (!nameCheck(e.target.files[0].name)) {
      toast({
        title: "File name error",
        description: "파일 이름으로 사용할 수 없는 문자가 사용되었습니다.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (!e.target.files[0].type.startsWith("audio")) {
      toast({
        title: "File type error",
        description: "파일 형식이 잘못되었습니다. (오디오가 아닙니다.)",
        status: "error",
        isClosable: true,
      });
      return;
    }
    setUploadAudioFile(e.target.files[0]);
    callback(e.target.files[0].name);
  };

  /*================================ Image ==============================*/

  const updateImageVisible = (value: boolean) => {
    if (value === false) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedImage.VisibleFile = false;
      newSetting.EmbedImage.VisibleText = false;
      setEditedSetting(newSetting);
    } else if (value === true) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedImage.VisibleFile = false;
      newSetting.EmbedImage.VisibleText = true;
      setEditedSetting(newSetting);
    }
    setImageVisible(value);
  };

  const updateImageInvisible = (value: boolean) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedImage.Invisible = value;
    setEditedSetting(newSetting);
  };

  const updateImageVisibleType = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedImage.VisibleFile = value === "File" ? true : false;
    newSetting.EmbedImage.VisibleText = value === "Text" ? true : false;
    setEditedSetting(newSetting);
  };

  const updateImagePath = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedImage.FileName = value;
    setEditedSetting(newSetting);
  };

  const updateImageMsg = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedImage.Text = value;
    setEditedSetting(newSetting);
  };

  const updateImageMsgType = (value: number) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedImage.TemplateType = value;
    setEditedSetting(newSetting);
  };

  /*================================ Audio ==============================*/

  const updateAudioInaudible = (value: boolean) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedAudio.Inaudible = value;
    setEditedSetting(newSetting);
  };

  const updateAudioAudible = (value: boolean) => {
    if (value === false) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedAudio.AudibleFile = false;
      newSetting.EmbedAudio.AudibleTts = false;
      setEditedSetting(newSetting);
    } else if (value === true) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedAudio.AudibleFile = false;
      newSetting.EmbedAudio.AudibleTts = true;
      setEditedSetting(newSetting);
    }
    setAudioAudible(value);
  };

  const updateAudioAudibleType = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedAudio.AudibleFile = value === "File" ? true : false;
    newSetting.EmbedAudio.AudibleTts = value === "Text" ? true : false;
    setEditedSetting(newSetting);
  };

  const updateAudioInterval = (value: number) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedAudio.Delay = value;
    setEditedSetting(newSetting);
  };

  const updateAudioMsg = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedAudio.TTSText = value;
    setEditedSetting(newSetting);
  };

  const updateAudioPath = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedAudio.FileName = value;
    setEditedSetting(newSetting);
  };

  /*================================ Video ==============================*/

  const updateVideoInaudible = (value: boolean) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.Inaudible = value;
    setEditedSetting(newSetting);
  };

  const updateVideoAudible = (value: boolean) => {
    if (value === false) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedVideo.AudibleFile = false;
      newSetting.EmbedVideo.AudibleTts = false;
      setEditedSetting(newSetting);
    } else if (value === true) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedVideo.AudibleFile = false;
      newSetting.EmbedVideo.AudibleTts = true;
      setEditedSetting(newSetting);
    }
    setVideoAudible(value);
  };

  const updateVideoVisible = (value: boolean) => {
    if (value === false) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedVideo.VisibleFile = false;
      newSetting.EmbedVideo.VisibleText = false;
      setEditedSetting(newSetting);
    } else if (value === true) {
      let newSetting: Setting = deepcopy(editedSetting);
      newSetting.EmbedVideo.VisibleFile = false;
      newSetting.EmbedVideo.VisibleText = true;
      setEditedSetting(newSetting);
    }
    setVideoVisible(value);
  };

  const updateVideoInterval = (value: number) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.Delay = value;
    setEditedSetting(newSetting);
  };

  const updateVideoAudibleType = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.AudibleFile = value === "File" ? true : false;
    newSetting.EmbedVideo.AudibleTts = value === "Text" ? true : false;
    setEditedSetting(newSetting);
  };

  const updateVideoAudibleMsg = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.TTSText = value;
    setEditedSetting(newSetting);
  };

  const updateVideoAudiblePath = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.AudioFileName = value;
    setEditedSetting(newSetting);
  };

  const updateVideoVisibleType = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.VisibleFile = value === "File" ? true : false;
    newSetting.EmbedVideo.VisibleText = value === "Text" ? true : false;
    setEditedSetting(newSetting);
  };

  const updateVideoVisibleMsg = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.Text = value;
    setEditedSetting(newSetting);
  };

  const updateVideoVisibleMsgType = (value: number) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.TemplateType = value;
    setEditedSetting(newSetting);
  };

  const updateVideoVisiblePath = (value: string) => {
    let newSetting: Setting = deepcopy(editedSetting);
    newSetting.EmbedVideo.VideoFileName = value;
    setEditedSetting(newSetting);
  };

  /*================================ Setting Upload Process ==============================*/

  const clearState = () => {
    setUploadAudioFile(undefined);
    setUploadImageFile(undefined);
    if (EmptySetting !== undefined) {
      setEditedSetting(deepcopy(EmptySetting));
    }
  };

  const closeModal = () => {
    clearState();
    onClose();
  };

  const upload = async () => {
    setIsUploading(true);
    try {
      if (!nameCheck(editedSetting.Name)) {
        throw new Error('잘못된 설정 이름입니다.\n % / : * ? " < > | 가 포함되었거나 공백은 사용이 불가능합니다.');
      }

      let reqBody: any = {};
      let uploadList: File[] = [];

      reqBody.SettingType = editedSetting.TargetType;
      switch (editedSetting.TargetType) {
        case "audio":
          reqBody.AudibleFile = editedSetting.EmbedAudio.AudibleFile;
          reqBody.AudibleTts = editedSetting.EmbedAudio.AudibleTts;
          reqBody.Inaudible = editedSetting.EmbedAudio.Inaudible;

          if (editedSetting.EmbedAudio.AudibleTts) {
            if (!editedSetting.EmbedAudio.Delay) {
              throw new Error("TTS 간격이 지정되지 않았습니다.");
            }
            reqBody.Delay = Math.max(editedSetting.EmbedAudio.Delay, 10);

            if (!editedSetting.EmbedAudio.TTSText) {
              throw new Error("삽입 TTS 텍스트가 지정되지 않았습니다.");
            }
            reqBody.TTSText = editedSetting.EmbedAudio.TTSText;
          }

          if (editedSetting.EmbedAudio.AudibleFile) {
            if (!editedSetting.EmbedAudio.Delay) {
              throw new Error("TTS 간격이 지정되지 않았습니다.");
            }
            reqBody.Delay = Math.max(editedSetting.EmbedAudio.Delay, 10);

            reqBody.AudioFileName = group + "/" + editedSetting.Name + "/" + editedSetting.EmbedAudio.FileName;

            if (uploadAudioFile) {
              uploadList.push(uploadAudioFile);
            } else {
              throw new Error("업로드할 파일이 없습니다.");
            }
          }
          break;

        case "image":
          reqBody.VisibleFile = editedSetting.EmbedImage.VisibleFile;
          reqBody.VisibleText = editedSetting.EmbedImage.VisibleText;
          reqBody.Invisible = editedSetting.EmbedImage.Invisible;

          if (editedSetting.EmbedImage.VisibleText) {
            if (!editedSetting.EmbedImage.Text || editedSetting.EmbedImage.Text === "") {
              throw new Error("삽입 텍스트가 지정되지 않았습니다.");
            }
            reqBody.Text = editedSetting.EmbedImage.Text;

            if (
              !editedSetting.EmbedImage.TemplateType ||
              editedSetting.EmbedImage.TemplateType < 1 ||
              editedSetting.EmbedImage.TemplateType > 4
            ) {
              throw new Error("삽입 방식이 지정되지 않았습니다.");
            }
            reqBody.TemplateType = editedSetting.EmbedImage.TemplateType;
          }

          if (editedSetting.EmbedImage.VisibleFile) {
            reqBody.ImageFileName = group + "/" + editedSetting.Name + "/" + editedSetting.EmbedImage.FileName;
            if (uploadImageFile) {
              uploadList.push(uploadImageFile);
            } else {
              throw new Error("업로드할 파일이 없습니다.");
            }
          }

          break;

        case "video":
          reqBody.VisibleFile = editedSetting.EmbedVideo.VisibleFile;
          reqBody.VisibleText = editedSetting.EmbedVideo.VisibleText;
          reqBody.Invisible = editedSetting.EmbedVideo.Invisible;

          if (editedSetting.EmbedVideo.VisibleText) {
            if (!editedSetting.EmbedVideo.Text || editedSetting.EmbedVideo.Text === "") {
              throw new Error("삽입 텍스트가 지정되지 않았습니다.");
            }
            reqBody.Text = editedSetting.EmbedVideo.Text;

            if (
              !editedSetting.EmbedVideo.TemplateType ||
              editedSetting.EmbedVideo.TemplateType < 1 ||
              editedSetting.EmbedVideo.TemplateType > 4
            ) {
              throw new Error("삽입 방식이 지정되지 않았습니다.");
            }
            reqBody.TemplateType = editedSetting.EmbedVideo.TemplateType;
          }

          /*========================*/

          reqBody.AudibleFile = editedSetting.EmbedVideo.AudibleFile;
          reqBody.AudibleTts = editedSetting.EmbedVideo.AudibleTts;
          reqBody.Inaudible = editedSetting.EmbedVideo.Inaudible;

          if (editedSetting.EmbedVideo.AudibleTts) {
            if (!editedSetting.EmbedVideo.TTSText) {
              throw new Error("삽입 TTS 텍스트가 지정되지 않았습니다.");
            }
            reqBody.TTSText = editedSetting.EmbedVideo.TTSText;

            if (!editedSetting.EmbedVideo.Delay) {
              throw new Error("TTS 간격이 지정되지 않았습니다.");
            }
            reqBody.Delay = Math.max(editedSetting.EmbedVideo.Delay, 10);
          }

          /*========================*/

          if (editedSetting.EmbedVideo.VisibleFile) {
            reqBody.VideoFileName = group + "/" + editedSetting.Name + "/" + editedSetting.EmbedVideo.VideoFileName;
            if (uploadImageFile) {
              uploadList.push(uploadImageFile);
            } else {
              throw new Error("업로드할 파일이 없습니다.");
            }
          }

          if (editedSetting.EmbedVideo.AudibleFile) {
            if (!editedSetting.EmbedVideo.Delay) {
              throw new Error("TTS 간격이 지정되지 않았습니다.");
            }
            reqBody.Delay = Math.max(editedSetting.EmbedVideo.Delay, 10);

            reqBody.AudioFileName = group + "/" + editedSetting.Name + "/" + editedSetting.EmbedVideo.AudioFileName;
            if (uploadAudioFile) {
              uploadList.push(uploadAudioFile);
            } else {
              throw new Error("업로드할 파일이 없습니다.");
            }
          }

          break;
      }

      for (let index = 0; index < uploadList.length; index++) {
        const element = uploadList[index];

        // get url
        let resp = await axios.post(`${PREURL.CREATE}`, {
          UploadType: "setting",
          GroupName: group,
          SettingName: editedSetting.Name,
          FileName: element.name,
        });
        const signedUrl = resp.data.SignedUrl;

        // upload file
        resp = await axios.put(signedUrl, element);
      }

      // save setting

      const resp = await axios.post(
        `${SETTING.CREATE}/${encodeURIComponent(group)}/${encodeURIComponent(editedSetting.Name)}`,
        reqBody,
      );

      setIsUploading(false);
      closeModal();
      toast({
        title: "설정 저장 완료",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      setIsUploading(false);
      closeModal();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${err.response?.data.message}`
          : `${err.cause}`;
        toast({
          title: "설정 저장 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "설정 저장 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  /*========================================================================================================================*/

  return (
    <Modal closeOnOverlayClick={false} isOpen={status} onClose={closeModal} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        {modalType === "Create" && <ModalHeader>Create New Setting</ModalHeader>}
        {modalType === "Edit" && <ModalHeader>{editedSetting.Name}</ModalHeader>}
        <ModalCloseButton disabled={isUploading} />
        <ModalBody pb={6} justifyContent={"center"}>
          <Center width={"100%"}>
            <VStack width={"100%"}>
              <Divider />
              {modalType === "Create" && (
                <Flex width={"100%"}>
                  <InputGroup>
                    <InputLeftAddon>Setting Name</InputLeftAddon>
                    <Input
                      value={editedSetting.Name}
                      isInvalid={!nameCheck(editedSetting.Name)}
                      onChange={(e) => updateSettingName(e.target.value)}
                    />
                    {!nameCheck(editedSetting.Name) && (
                      <InputRightElement w={"fit-content"} pr={"3"}>
                        <Text color={"red"}>{'특수문자 사용금지 ( % / : * ? " < > | ) '}</Text>
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Flex>
              )}
              {modalType === "Edit" && <Text>Type: {editedSetting.TargetType}</Text>}
              <Divider />
              <Tabs
                width={"100%"}
                isFitted
                index={typeToNumber(editedSetting.TargetType)}
                onChange={(e) => {
                  if (modalType !== "Edit") {
                    clearState();
                    updateSettingType(e);
                  }
                }}
              >
                {modalType === "Edit" && (
                  <TabList>
                    <Tab isDisabled={editedSetting.TargetType !== "image"}>Image</Tab>
                    <Tab isDisabled={editedSetting.TargetType !== "audio"}>Audio</Tab>
                    <Tab isDisabled={editedSetting.TargetType !== "video"}>Video</Tab>
                  </TabList>
                )}
                {modalType === "Create" && (
                  <TabList>
                    <Tab>Image</Tab>
                    <Tab>Audio</Tab>
                    <Tab>Video</Tab>
                  </TabList>
                )}
                <TabPanels>
                  {/*======================================================= Image Setting =======================================================*/}
                  <TabPanel>
                    <Text>삽입할 워터마크들을 선택해주세요. (Invisible, visible 공동선택 가능)</Text>
                    <List mt={"5px"} mb={"5px"}>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={editedSetting.EmbedImage.Invisible}
                          onChange={(e) => updateImageInvisible(e.target.checked)}
                        >
                          Invisible 워터마크
                        </Checkbox>
                      </ListItem>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={imageVisible}
                          onChange={(e) => updateImageVisible(e.target.checked)}
                        >
                          Visible 워터마크
                        </Checkbox>
                      </ListItem>
                    </List>
                    {imageVisible && (
                      <VStack width={"100%"}>
                        <Text width={"90%"}>Visible 워터마크 설정</Text>
                        <Divider width={"90%"} />
                        <Flex width={"90%"}>
                          <Text>삽입 방식 : </Text>
                          <RadioGroup
                            ml={"10px"}
                            flexDirection={"row"}
                            value={editedSetting.EmbedImage.VisibleFile ? "File" : "Text"}
                            onChange={(e) => updateImageVisibleType(e)}
                          >
                            <Radio value={"Text"} ml={"10px"}>
                              Text
                            </Radio>
                            <Radio value={"File"} ml={"10px"}>
                              File
                            </Radio>
                          </RadioGroup>
                        </Flex>
                        {!editedSetting.EmbedImage.VisibleFile ? (
                          <Box width={"90%"}>
                            <Text mt={"5px"}>워터마크 메세지</Text>
                            <Input value={editedSetting.EmbedImage.Text} onChange={(e) => updateImageMsg(e.target.value)} />
                            <Text mt={"5px"}>워터마크 삽입 방식</Text>
                            <RadioGroup
                              flexDirection={"row"}
                              value={editedSetting.EmbedImage.TemplateType?.toString()}
                              onChange={(e) => updateImageMsgType(Number(e))}
                            >
                              <Radio value={"1"} ml={"10px"}>
                                기본
                                <Image src={template0} alt={"base"} />
                              </Radio>
                              <Radio value={"2"} ml={"10px"}>
                                사선
                                <Image src={template1} alt={"diag"} />
                              </Radio>
                              <Radio value={"3"} ml={"10px"}>
                                라인
                                <Image src={template2} alt={"line"} />
                              </Radio>
                              <Radio value={"4"} ml={"10px"}>
                                로고
                                <Image src={template3} alt={"logo"} />
                              </Radio>
                            </RadioGroup>
                          </Box>
                        ) : (
                          <Box>
                            <VStack>
                              <Center>
                                <Text>삽입할 이미지를 업로드합니다.</Text>
                              </Center>
                              {setting.EmbedImage.FileName && (
                                <Center>
                                  <Highlight query={setting.EmbedImage.FileName} styles={{ px: "1", bg: "red.100" }}>
                                    {`현재 ${setting.EmbedImage.FileName} 이 설정되어 있습니다.`}
                                  </Highlight>
                                </Center>
                              )}
                              <Dropzone
                                multiple={false}
                                width={"400px"}
                                onChange={(e) => updateImageUploadFile(e, updateImagePath)}
                              />
                              {uploadImageFile && (
                                <Center>
                                  <Highlight query={uploadImageFile.name} styles={{ px: "1", bg: "teal.100" }}>
                                    {`${uploadImageFile.name} 을 사용합니다.`}
                                  </Highlight>
                                </Center>
                              )}
                            </VStack>
                          </Box>
                        )}
                      </VStack>
                    )}
                  </TabPanel>
                  {/*======================================================= Audio Setting =======================================================*/}
                  <TabPanel>
                    <Text>삽입할 워터마크들을 선택해주세요. (Inaudible, audible 공동선택 가능)</Text>
                    <List mt={"5px"} mb={"5px"}>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={editedSetting.EmbedAudio.Inaudible}
                          onChange={(e) => updateAudioInaudible(e.target.checked)}
                        >
                          Inaudible 워터마크
                        </Checkbox>
                      </ListItem>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={audioAudible}
                          onChange={(e) => updateAudioAudible(e.target.checked)}
                        >
                          Audible 워터마크
                        </Checkbox>
                      </ListItem>
                    </List>

                    {audioAudible && (
                      <VStack width={"100%"}>
                        <Text width={"90%"}>Audible 워터마크 설정</Text>
                        <Divider width={"90%"} />
                        <Flex width={"90%"} align={"center"}>
                          <Text>삽입 간격 : </Text>

                          <NumberInput
                            ml={"10px"}
                            value={editedSetting.EmbedAudio.Delay}
                            min={10}
                            onChange={(e) => updateAudioInterval(Number(e))}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>

                          <Text ml={"3px"}>초 (최소 10초)</Text>
                        </Flex>
                        <Divider width={"90%"} />
                        <Flex width={"90%"}>
                          <Text>삽입 방식 : </Text>
                          <RadioGroup
                            ml={"10px"}
                            flexDirection={"row"}
                            value={editedSetting.EmbedAudio.AudibleFile ? "File" : "Text"}
                            onChange={(e) => updateAudioAudibleType(e)}
                          >
                            <Radio value={"Text"} ml={"10px"}>
                              TTS
                            </Radio>
                            <Radio value={"File"} ml={"10px"}>
                              File
                            </Radio>
                          </RadioGroup>
                        </Flex>
                        {!editedSetting.EmbedAudio.AudibleFile ? (
                          <Box width={"90%"}>
                            <Text mt={"5px"}>워터마크 메세지</Text>
                            <Input
                              value={editedSetting.EmbedAudio.TTSText}
                              onChange={(e) => updateAudioMsg(e.target.value)}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <VStack>
                              <Center>
                                <Text>삽입할 오디오를 업로드합니다.</Text>
                              </Center>
                              {setting.EmbedAudio.FileName && (
                                <Center>
                                  <Highlight query={setting.EmbedAudio.FileName} styles={{ px: "1", bg: "red.100" }}>
                                    {`현재 ${setting.EmbedAudio.FileName} 이 설정되어 있습니다.`}
                                  </Highlight>
                                </Center>
                              )}
                              <Dropzone
                                multiple={false}
                                width={"400px"}
                                onChange={(e) => updateAudioUploadFile(e, updateAudioPath)}
                              />
                              {uploadAudioFile && (
                                <Center>
                                  <Highlight query={uploadAudioFile.name} styles={{ px: "1", bg: "teal.100" }}>
                                    {`${uploadAudioFile.name} 을 사용합니다.`}
                                  </Highlight>
                                </Center>
                              )}
                            </VStack>
                          </Box>
                        )}
                      </VStack>
                    )}
                  </TabPanel>
                  {/*======================================================= Video Setting =======================================================*/}
                  <TabPanel>
                    <Text>삽입할 워터마크들을 선택해주세요. (Inaudible, audible, visible 공동선택 가능)</Text>
                    <List mt={"5px"} mb={"5px"}>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={editedSetting.EmbedVideo.Inaudible}
                          onChange={(e) => updateVideoInaudible(e.target.checked)}
                        >
                          Inaudible 워터마크
                        </Checkbox>
                      </ListItem>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={videoAudible}
                          onChange={(e) => updateVideoAudible(e.target.checked)}
                        >
                          Audible 워터마크
                        </Checkbox>
                      </ListItem>
                      <ListItem>
                        <Checkbox
                          colorScheme={"green"}
                          isChecked={videoVisible}
                          onChange={(e) => updateVideoVisible(e.target.checked)}
                        >
                          Visible 워터마크
                        </Checkbox>
                      </ListItem>
                    </List>
                    {videoAudible && (
                      <VStack width={"100%"} mt={"15px"}>
                        <Text width={"90%"}>Audible 워터마크 설정</Text>
                        <Divider width={"90%"} />
                        <Flex width={"90%"} align={"center"}>
                          <Text>삽입 간격 : </Text>

                          <NumberInput
                            ml={"10px"}
                            value={editedSetting.EmbedVideo.Delay}
                            min={10}
                            onChange={(e) => updateVideoInterval(Number(e))}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>

                          <Text ml={"3px"}>초 (최소 10초)</Text>
                        </Flex>
                        <Divider width={"90%"} />
                        <Flex width={"90%"}>
                          <Text>삽입 방식 : </Text>
                          <RadioGroup
                            ml={"10px"}
                            flexDirection={"row"}
                            value={editedSetting.EmbedVideo.AudibleFile ? "File" : "Text"}
                            onChange={(e) => updateVideoAudibleType(e)}
                          >
                            <Radio value={"Text"} ml={"10px"}>
                              TTS
                            </Radio>
                            <Radio value={"File"} ml={"10px"}>
                              File
                            </Radio>
                          </RadioGroup>
                        </Flex>
                        {!editedSetting.EmbedVideo.AudibleFile ? (
                          <Box width={"90%"}>
                            <Text mt={"5px"}>워터마크 메세지</Text>
                            <Input
                              value={editedSetting.EmbedVideo.TTSText}
                              onChange={(e) => updateVideoAudibleMsg(e.target.value)}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <VStack>
                              <Center>
                                <Text>삽입할 오디오를 업로드합니다.</Text>
                              </Center>
                              {setting.EmbedVideo.AudioFileName && (
                                <Center>
                                  <Highlight query={setting.EmbedVideo.AudioFileName} styles={{ px: "1", bg: "red.100" }}>
                                    {`현재 ${setting.EmbedVideo.AudioFileName} 이 설정되어 있습니다.`}
                                  </Highlight>
                                </Center>
                              )}
                              <Dropzone
                                multiple={false}
                                width={"400px"}
                                onChange={(e) => updateAudioUploadFile(e, updateVideoAudiblePath)}
                              />
                              {uploadAudioFile && (
                                <Center>
                                  <Highlight query={uploadAudioFile.name} styles={{ px: "1", bg: "teal.100" }}>
                                    {`${uploadAudioFile.name} 을 사용합니다.`}
                                  </Highlight>
                                </Center>
                              )}
                            </VStack>
                          </Box>
                        )}
                      </VStack>
                    )}
                    {videoVisible && (
                      <VStack width={"100%"} mt={"15px"}>
                        <Text width={"90%"}>Visible 워터마크 설정</Text>
                        <Divider width={"90%"} />
                        <Flex width={"90%"}>
                          <Text>삽입 방식 : </Text>
                          <RadioGroup
                            ml={"10px"}
                            flexDirection={"row"}
                            value={editedSetting.EmbedVideo.VisibleFile ? "File" : "Text"}
                            onChange={(e) => updateVideoVisibleType(e)}
                          >
                            <Radio value={"Text"} ml={"10px"}>
                              Text
                            </Radio>
                            <Radio value={"File"} ml={"10px"}>
                              File
                            </Radio>
                          </RadioGroup>
                        </Flex>
                        {!editedSetting.EmbedVideo.VisibleFile ? (
                          <Box width={"90%"}>
                            <Text mt={"5px"}>워터마크 메세지</Text>
                            <Input
                              value={editedSetting.EmbedVideo.Text}
                              onChange={(e) => updateVideoVisibleMsg(e.target.value)}
                            />
                            <Text mt={"5px"}>워터마크 삽입 방식</Text>
                            <RadioGroup
                              flexDirection={"row"}
                              value={editedSetting.EmbedVideo.TemplateType?.toString()}
                              onChange={(e) => updateVideoVisibleMsgType(Number(e))}
                            >
                              <Radio value={"1"} ml={"10px"}>
                                기본
                                <Image src={template0} alt={"base"} />
                              </Radio>
                              <Radio value={"2"} ml={"10px"}>
                                사선
                                <Image src={template1} alt={"diag"} />
                              </Radio>
                              <Radio value={"3"} ml={"10px"}>
                                라인
                                <Image src={template2} alt={"line"} />
                              </Radio>
                              <Radio value={"4"} ml={"10px"}>
                                로고
                                <Image src={template3} alt={"logo"} />
                              </Radio>
                            </RadioGroup>
                          </Box>
                        ) : (
                          <Box>
                            <VStack>
                              <Center>
                                <Text>삽입할 이미지를 업로드합니다.</Text>
                              </Center>
                              {setting.EmbedVideo.VideoFileName && (
                                <Center>
                                  <Highlight query={setting.EmbedVideo.VideoFileName} styles={{ px: "1", bg: "red.100" }}>
                                    {`현재 ${setting.EmbedVideo.VideoFileName} 이 설정되어 있습니다.`}
                                  </Highlight>
                                </Center>
                              )}
                              <Dropzone
                                multiple={false}
                                width={"400px"}
                                onChange={(e) => updateImageUploadFile(e, updateVideoVisiblePath)}
                              />
                              {uploadImageFile && (
                                <Center>
                                  <Highlight query={uploadImageFile.name} styles={{ px: "1", bg: "teal.100" }}>
                                    {`${uploadImageFile.name} 을 사용합니다.`}
                                  </Highlight>
                                </Center>
                              )}
                            </VStack>
                          </Box>
                        )}
                      </VStack>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
              {modalType === "Edit" && (
                <>
                  <Divider />
                  <Text>이미 작업된 파일들의 설정은 바뀌지 않습니다.</Text>
                </>
              )}
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => upload()} colorScheme={"blue"} mr={3}>
            Apply
          </Button>
          <Button onClick={closeModal} colorScheme={"red"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateModal;
