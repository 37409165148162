import React from "react";

import { Divider, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, VStack } from "@chakra-ui/react";
import { useGroup } from "hooks/useGroup";

const AudioStat = ({ setting }: { setting: Setting }): React.ReactElement => {
  const { selected: group } = useGroup();
  return (
    <VStack divider={<Divider />}>
      <StatGroup width={"100%"} textAlign={"left"}>
        <Stat>
          <StatLabel>Setting Name</StatLabel>
          <StatNumber>{setting.Name}</StatNumber>
          <StatHelpText>세팅 프리셋 이름</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Group</StatLabel>
          <StatNumber>{group}</StatNumber>
          <StatHelpText>세팅 프리셋 소속 그룹</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>MediaType</StatLabel>
          <StatNumber>{setting.TargetType}</StatNumber>
          <StatHelpText>설정을 적용할 미디어파일 유형</StatHelpText>
        </Stat>
      </StatGroup>
      <StatGroup width={"100%"} textAlign={"left"}>
        <Stat>
          <StatLabel>Inaudible</StatLabel>
          <StatNumber>{setting.EmbedAudio.Inaudible ? "True" : "False"}</StatNumber>
          <StatHelpText>Inaudible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Audible</StatLabel>
          <StatNumber>{setting.EmbedAudio.AudibleTts || setting.EmbedAudio.AudibleFile ? "True" : "False"}</StatNumber>
          <StatHelpText>Audible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Audible Type</StatLabel>
          <StatNumber>
            {setting.EmbedAudio.AudibleFile && "File"}
            {setting.EmbedAudio.AudibleTts && "TTS"}
            {!setting.EmbedAudio.AudibleTts && !setting.EmbedAudio.AudibleFile && "None"}
          </StatNumber>
          <StatHelpText>Audible Watermark 타입</StatHelpText>
        </Stat>
      </StatGroup>
      {(setting.EmbedAudio.AudibleFile || setting.EmbedAudio.AudibleTts) && (
        <StatGroup width={"100%"} textAlign={"left"} flexGrow={0}>
          <Stat>
            <StatLabel>Interval</StatLabel>
            <StatNumber> {setting.EmbedAudio.Delay}</StatNumber>
            <StatHelpText>삽입 간격</StatHelpText>
          </Stat>
          {setting.EmbedAudio.AudibleTts && (
            <>
              <Stat>
                <StatLabel>Watermark Message Type</StatLabel>
                <StatNumber>{setting.EmbedAudio.TTSText}</StatNumber>
                <StatHelpText>워터마크 삽입 방식</StatHelpText>
              </Stat>
            </>
          )}
          {setting.EmbedAudio.AudibleFile && (
            <>
              <Stat>
                <StatLabel>Image File</StatLabel>
                <StatNumber>{setting.EmbedAudio.FileName}</StatNumber>
                <StatHelpText>삽입하는 파일</StatHelpText>
              </Stat>
            </>
          )}
        </StatGroup>
      )}
    </VStack>
  );
};

export default AudioStat;
