import { Box, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const FileName = ({ filename, setting }: { filename: string; setting: string }): React.ReactElement => {
  const [file, setFile] = useState<string>("");

  useEffect(() => {
    const info = filename.split("/");
    setFile(info[info.length - 1]);
  }, [filename]);

  return (
    <Tooltip label={filename}>
      <Box>
        <Text w={"350px"} textOverflow={"ellipsis"} overflow={"hidden"}>
          {setting}
        </Text>
        <Text as={"b"} w={"350px"} textOverflow={"ellipsis"} overflow={"hidden"}>
          {file}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default FileName;
