import React from "react";

import { Center, VStack, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Box } from "@chakra-ui/react";

const SettingStage = ({
  selected,
  onChange,
  settings,
}: {
  selected?: string;
  onChange: (g: Setting) => void;
  settings: Setting[];
}): React.ReactElement => {
  return (
    <Center width={"100%"} height={"100%"}>
      <VStack width={"70%"} height={"100%"}>
        <Text height={"50px"}>1. 적용할 설정을 선택합니다.</Text>
        <Box width={"100%"} height={"450px"} overflowY={"scroll"}>
          <TableContainer width={"100%"}>
            <Table variant={"simple"}>
              <Thead>
                <Tr>
                  <Th>
                    <Center>Name</Center>
                  </Th>
                  <Th>
                    <Center>Type</Center>
                  </Th>
                  <Th>
                    <Center>Interface</Center>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {settings
                  .sort((l, r) => {
                    if (Date.parse(l.CreatedAt) > Date.parse(r.CreatedAt)) return -1;
                    if (Date.parse(l.CreatedAt) < Date.parse(r.CreatedAt)) return 1;
                    return 0;
                  })
                  .map((value, key) => (
                    <Tr key={key} bg={value.Name === selected ? "teal.100" : "white"}>
                      <Td>{value.Name}</Td>
                      <Td width={"75px"}>{value.TargetType}</Td>
                      <Td width={"100px"}>
                        <Center>
                          <Button onClick={() => onChange(value)}>select</Button>
                        </Center>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </VStack>
    </Center>
  );
};

export default SettingStage;
