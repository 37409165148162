import React from "react";

import { Divider, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, VStack } from "@chakra-ui/react";
import { useGroup } from "hooks/useGroup";

const convVisibleType = (type: number): string => {
  switch (type) {
    case 1:
      return "기본";
    case 2:
      return "사선";
    case 3:
      return "라인";
    case 4:
      return "로고";
    default:
      return "";
  }
};

const ImageStat = ({ setting }: { setting: Setting }): React.ReactElement => {
  const { selected: group } = useGroup();

  return (
    <VStack divider={<Divider />}>
      <StatGroup width={"100%"} textAlign={"left"}>
        <Stat>
          <StatLabel>Setting Name</StatLabel>
          <StatNumber>{setting.Name}</StatNumber>
          <StatHelpText>세팅 프리셋 이름</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Group</StatLabel>
          <StatNumber>{group}</StatNumber>
          <StatHelpText>세팅 프리셋 소속 그룹</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>MediaType</StatLabel>
          <StatNumber>{setting.TargetType}</StatNumber>
          <StatHelpText>설정을 적용할 미디어파일 유형</StatHelpText>
        </Stat>
      </StatGroup>
      <StatGroup width={"100%"} textAlign={"left"} flexGrow={0}>
        <Stat>
          <StatLabel>Invisible</StatLabel>
          <StatNumber>{setting.EmbedImage.Invisible ? "True" : "False"}</StatNumber>
          <StatHelpText>Invisible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Visible</StatLabel>
          <StatNumber>{setting.EmbedImage.VisibleFile || setting.EmbedImage.VisibleText ? "True" : "False"}</StatNumber>
          <StatHelpText>Visible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Visible Type</StatLabel>
          <StatNumber>
            {setting.EmbedImage.VisibleFile && "File"}
            {setting.EmbedImage.VisibleText && "Text"}
            {!setting.EmbedImage.VisibleFile && !setting.EmbedImage.VisibleText && "None"}
          </StatNumber>
          <StatHelpText>Visible Watermark 타입</StatHelpText>
        </Stat>
      </StatGroup>
      {(setting.EmbedImage.VisibleFile || setting.EmbedImage.VisibleText) && (
        <StatGroup width={"100%"} textAlign={"left"} flexGrow={0}>
          {setting.EmbedImage.VisibleText && (
            <>
              <Stat>
                <StatLabel>Watermark Message</StatLabel>
                <StatNumber>{setting.EmbedImage.Text}</StatNumber>
                <StatHelpText>삽입하는 메세지</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Watermark Message Type</StatLabel>
                <StatNumber>
                  {setting.EmbedImage.TemplateType} - {convVisibleType(setting.EmbedImage.TemplateType || 0)}
                </StatNumber>
                <StatHelpText>워터마크 삽입 방식</StatHelpText>
              </Stat>
            </>
          )}
          {setting.EmbedImage.VisibleFile && (
            <>
              <Stat>
                <StatLabel>Image File</StatLabel>
                <StatNumber>{setting.EmbedImage.FileName}</StatNumber>
                <StatHelpText>삽입하는 파일</StatHelpText>
              </Stat>
            </>
          )}
        </StatGroup>
      )}
    </VStack>
  );
};

export default ImageStat;
