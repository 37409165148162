import React, { createContext, useMemo, useContext, useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import axios, { isAxiosError } from "axios";
import { GROUP } from "config/config";
import { useToast } from "@chakra-ui/react";

interface Group {
  isRoot: boolean;
  groups: string[];
  selected: string;
  changeGroup: (p: string) => void;
}

export const GroupContext = createContext<Group>({
  isRoot: false,
  groups: [],
  selected: "",
  changeGroup: (p: string) => {},
});

export const GroupProvider = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => {
  const toast = useToast();
  const { login, email, logout } = useAuth();

  const [isRoot, setIsRoot] = useState<boolean>(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>("");

  const updateGroups = async () => {
    try {
      console.log("UpdateGroups Invoke");

      const resp = await axios.post(`${GROUP.SEARCH_LIST_USER}`, { UserName: email }, { timeout: 30000 });

      const respGroups: string[] = resp.data.Groups;

      if (respGroups.length <= 0) {
        throw new Error("관리자에게 계정 등록을 요청하세요.");
      }

      if (resp.data.IsRoot === undefined) {
        throw new Error("API에러: 관리자에게 문의 부탁드립니다.");
      }

      setIsRoot(resp.data.IsRoot);
      setGroups(respGroups);
      setSelected(respGroups[0]);

      login();

      if (respGroups[0] === "WaitGroup") {
        toast({
          title: "그룹 검색 완료",
          description: "WaitGroup 그룹은 삽입 요청이 불가능합니다. 그룹을 변경하거나 관리자에게 그룹 추가를 요청하세요.",
          status: "info",
          isClosable: false,
        });
      } else {
        toast({
          title: "그룹 검색 완료",
          status: "success",
          isClosable: true,
          duration: 10000,
        });
      }
    } catch (err) {
      logout();
      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;
        toast({
          title: "그룹 검색 실패",
          description: description + " 구글 계정 로그아웃",
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "그룹 검색 실패",
          description: `${err}` + "구글 계정 로그아웃",
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  useEffect(() => {
    console.log("email change");
    if (email !== "") {
      updateGroups();
    }
  }, [email]);

  const value = useMemo<Group>(
    () => ({
      isRoot: isRoot,
      groups: groups,
      selected: selected,
      changeGroup: (g) => {
        if (g === "WaitGroup") {
          toast({
            title: "Success",
            description: "WaitGroup 그룹은 삽입 요청이 불가능합니다. 그룹을 변경하거나 관리자에게 그룹 추가를 요청하세요.",
            status: "info",
            isClosable: true,
            duration: 10000,
          });
        }
        setSelected(g);
      },
    }),
    [selected, groups, email, isRoot],
  );

  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>;
};

export const useGroup = (): Group => {
  return useContext(GroupContext);
};
