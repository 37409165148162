import React from "react";

import { Divider, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, VStack } from "@chakra-ui/react";
import { useGroup } from "hooks/useGroup";

const convVisibleType = (type: number): string => {
  switch (type) {
    case 1:
      return "기본";
    case 2:
      return "사선";
    case 3:
      return "라인";
    case 4:
      return "로고";
    default:
      return "";
  }
};

const VideoStat = ({ setting }: { setting: Setting }): React.ReactElement => {
  const { selected: group } = useGroup();

  return (
    <VStack divider={<Divider />}>
      <StatGroup width={"100%"} textAlign={"left"}>
        <Stat>
          <StatLabel>Setting Name</StatLabel>
          <StatNumber>{setting.Name}</StatNumber>
          <StatHelpText>세팅 프리셋 이름</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Group</StatLabel>
          <StatNumber>{group}</StatNumber>
          <StatHelpText>세팅 프리셋 소속 그룹</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>MediaType</StatLabel>
          <StatNumber>{setting.TargetType}</StatNumber>
          <StatHelpText>설정을 적용할 미디어파일 유형</StatHelpText>
        </Stat>
      </StatGroup>
      <StatGroup width={"100%"} textAlign={"left"} flexGrow={0}>
        <Stat>
          <StatLabel>Inaudible</StatLabel>
          <StatNumber>{setting.EmbedVideo.Inaudible ? "True" : "False"}</StatNumber>
          <StatHelpText>Inaudible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Audible</StatLabel>
          <StatNumber>{setting.EmbedVideo.AudibleTts || setting.EmbedVideo.AudibleFile ? "True" : "False"}</StatNumber>
          <StatHelpText>Audible Watermark 삽입여부</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Visible</StatLabel>
          <StatNumber>{setting.EmbedVideo.VisibleFile || setting.EmbedVideo.VisibleText ? "True" : "False"}</StatNumber>
          <StatHelpText>Visible Watermark 삽입여부</StatHelpText>
        </Stat>
      </StatGroup>
      {(setting.EmbedVideo.AudibleFile || setting.EmbedVideo.AudibleTts) && (
        <StatGroup width={"100%"} textAlign={"left"}>
          <Stat>
            <StatLabel>Audible Type</StatLabel>
            <StatNumber>{setting.EmbedVideo.AudibleFile ? "File" : "TTS"}</StatNumber>
            <StatHelpText>Audible Watermark 타입</StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Interval</StatLabel>
            <StatNumber> {setting.EmbedVideo.Delay}</StatNumber>
            <StatHelpText>삽입 간격</StatHelpText>
          </Stat>
          {setting.EmbedVideo.AudibleTts && (
            <>
              <Stat>
                <StatLabel>Audible Watermark Message</StatLabel>
                <StatNumber>{setting.EmbedVideo.TTSText}</StatNumber>
                <StatHelpText>삽입하는 메세지</StatHelpText>
              </Stat>
            </>
          )}
          {setting.EmbedVideo.AudibleFile && (
            <>
              <Stat>
                <StatLabel>Audio File</StatLabel>
                <StatNumber>{setting.EmbedVideo.AudioFileName}</StatNumber>
                <StatHelpText>삽입 파일</StatHelpText>
              </Stat>
            </>
          )}
        </StatGroup>
      )}
      {(setting.EmbedVideo.VisibleFile || setting.EmbedVideo.VisibleText) && (
        <StatGroup width={"100%"} textAlign={"left"}>
          <Stat>
            <StatLabel>Visible Type</StatLabel>
            <StatNumber>{setting.EmbedVideo.VisibleFile ? "File" : "Text"}</StatNumber>
            <StatHelpText>Visible Watermark 타입</StatHelpText>
          </Stat>
          {setting.EmbedVideo.VisibleText && (
            <>
              <Stat>
                <StatLabel>Visible Watermark Message</StatLabel>
                <StatNumber>{setting.EmbedVideo.Text}</StatNumber>
                <StatHelpText>삽입하는 메세지</StatHelpText>
              </Stat>
              <Stat>
                <StatLabel>Watermark Message Type</StatLabel>
                <StatNumber>
                  {setting.EmbedVideo.TemplateType} - {convVisibleType(setting.EmbedVideo.TemplateType || 0)}
                </StatNumber>
                <StatHelpText>워터마크 삽입 방식</StatHelpText>
              </Stat>
            </>
          )}
          {setting.EmbedVideo.VisibleFile && (
            <>
              <Stat>
                <StatLabel>Image File</StatLabel>
                <StatNumber>{setting.EmbedVideo.VideoFileName}</StatNumber>
                <StatHelpText>삽입하는 파일</StatHelpText>
              </Stat>
            </>
          )}
        </StatGroup>
      )}
    </VStack>
  );
};

export default VideoStat;
