import React from "react";

import { Box, CardBody, Card, CardHeader, Heading } from "@chakra-ui/react";
import VideoStat from "./VideoStat";
import ImageStat from "./ImageStat";
import AudioStat from "./AudioStat";

const convVisibleType = (type: number): string => {
  switch (type) {
    case 1:
      return "기본";
    case 2:
      return "사선";
    case 3:
      return "라인";
    case 4:
      return "로고";
    default:
      return "";
  }
};

const SettingStat = ({ setting }: { setting: Setting }): React.ReactElement => {
  return (
    <Card minWidth={"800px"} width={"80vw"} justify={"left"}>
      <CardHeader>
        <Heading size={"md"} textAlign={"left"}>
          Setting Info
        </Heading>
      </CardHeader>
      <CardBody>
        <Box>
          {setting.TargetType === "image" && <ImageStat setting={setting} />}
          {setting.TargetType === "audio" && <AudioStat setting={setting} />}
          {setting.TargetType === "video" && <VideoStat setting={setting} />}
        </Box>
      </CardBody>
    </Card>
  );
};

export default SettingStat;
