import { Button, Center, HStack, Td, Tr, useDisclosure } from "@chakra-ui/react";

import React from "react";

import DeleteModal from "components/settings/Modal/Delete";
import UpdateModal from "components/settings/Modal/Update";
import { useGroup } from "hooks/useGroup";

interface ItemProps {
  setting: Setting;
  callback: () => {};
}

const Item = ({ setting, callback }: ItemProps): React.ReactElement => {
  const { isOpen: editModalStatus, onOpen: editModalOpen, onClose: editModalClose } = useDisclosure();
  const { isOpen: delModalStatus, onOpen: delModalOpen, onClose: delModalClose } = useDisclosure();

  const { selected: group } = useGroup();

  return (
    <Tr>
      <Td>{setting.Name}</Td>
      <Td width={"150px"}>
        <Center>{setting.TargetType}</Center>
      </Td>
      <Td width={"400px"}>
        <Center>
          <HStack>
            <Button variant={"link"} colorScheme={"teal"} onClick={editModalOpen}>
              Edit
            </Button>
            <Button variant={"link"} colorScheme={"red"} onClick={delModalOpen}>
              Delete
            </Button>

            <UpdateModal
              group={group}
              modalType={"Edit"}
              status={editModalStatus}
              onClose={() => {
                editModalClose();
                callback();
              }}
              setting={setting}
            />
            <DeleteModal
              group={group}
              modalType={"Edit"} // 어차피 무시됨
              status={delModalStatus}
              onClose={() => {
                delModalClose();
                callback();
              }}
              setting={setting}
            />
          </HStack>
        </Center>
      </Td>
    </Tr>
  );
};

export default Item;
