import React, { useState, useEffect } from "react";

import { EmptyLinkReq } from "model/link";

import {
  Flex,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftAddon,
  Input,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Checkbox,
  InputRightElement,
} from "@chakra-ui/react";
import { LINK } from "config/config";
import axios, { isAxiosError } from "axios";
import { useGroup } from "hooks/useGroup";

const deepcopy = (obj: LinkReq | undefined): LinkReq => {
  return JSON.parse(JSON.stringify(obj));
};

const nameCheck = (name: string): boolean => {
  var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  var rg2 = /^\./; // cannot start with dot (.)
  var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  var rg4 = /%/;

  return rg1.test(name) && !rg2.test(name) && !rg3.test(name) && !rg4.test(name);
};

const UpdateModal = ({
  status,
  link,
  sourceFile,
  onClose,
}: {
  status: boolean;
  link?: Link;
  sourceFile: string;
  onClose: () => void;
}): React.ReactElement => {
  const toast = useToast();

  const [minDate, setMinDate] = useState<string>("");
  const [maxDate, setMaxDate] = useState<string>("");

  const { selected: group } = useGroup();
  const [edittedLinkReq, setEdittedLinkReq] = useState<LinkReq>(EmptyLinkReq);

  const closeModal = () => {
    setEdittedLinkReq(EmptyLinkReq);
    onClose();
  };

  useEffect(() => {
    if (link) {
      const newLinkReq = deepcopy(EmptyLinkReq);
      newLinkReq.LinkSeq = link.LinkSeq;
      newLinkReq.S3FilePath = link.SourcePath;
      newLinkReq.TypeURL = link.Link.substring(LINK.PREFIX.length);
      newLinkReq.ExpireCount = link.Count;
      newLinkReq.ExpireDate = link.Expire;
      newLinkReq.AutoDelete = link.AutoDelete;
      setEdittedLinkReq(newLinkReq);
    }

    const date = new Date();
    setMinDate(date.toISOString().substring(0, 10));
    const next = new Date(date.setMonth(date.getMonth() + 1));
    setMaxDate(next.toISOString().substring(0, 10));
  }, [status]);

  /*========================================================================================================================*/

  const requestLinkUpdate = async () => {
    try {
      if (edittedLinkReq.TypeURL !== "" && !nameCheck(edittedLinkReq.TypeURL)) {
        throw new Error('잘못된 링크 경로니다.\n % / : * ? " < > | 가 포함된 경로는 불가능합니다.');
      }

      edittedLinkReq.BucketName = LINK.BUCKET;
      edittedLinkReq.AccessToken = "";
      edittedLinkReq.S3FilePath = sourceFile;

      // 아래는 선택 사항이기에 요청 사항에 포함이 되면 안되는 경우 바디에서 제외시킴
      if (edittedLinkReq.ExpireDate === null || edittedLinkReq.ExpireDate === "") {
        delete edittedLinkReq.ExpireDate;
      } else if (edittedLinkReq.ExpireDate) {
        edittedLinkReq.ExpireDate = new Date(edittedLinkReq.ExpireDate || "").toISOString();
      }

      if (edittedLinkReq.ExpireCount === undefined || edittedLinkReq.ExpireCount <= 0) {
        edittedLinkReq.ExpireCount = -1;
      }

      if (link) {
        // patch
        const _ = await axios.patch(`${LINK.CREATE}/${encodeURIComponent(group)}`, edittedLinkReq);
      } else {
        // create
        const _ = await axios.post(`${LINK.CREATE}/${encodeURIComponent(group)}`, edittedLinkReq);
      }
      closeModal();
      toast({
        title: "링크 갱신 완료",
        status: "success",
        isClosable: true,
        duration: 10000,
      });
    } catch (err) {
      closeModal();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;

        toast({
          title: "링크 갱신 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "링크 갱신 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  /*========================================================================================================================*/

  const updateLinkUrl = (value: string) => {
    const newLink = deepcopy(edittedLinkReq);
    newLink.TypeURL = value;
    setEdittedLinkReq(newLink);
  };

  const updateLinkCount = (value: number) => {
    const newLink = deepcopy(edittedLinkReq);
    newLink.ExpireCount = value > 0 ? value : -1;
    setEdittedLinkReq(newLink);
  };

  const updateLinkExpireDate = (value: string) => {
    const newLink = deepcopy(edittedLinkReq);
    newLink.ExpireDate = value;
    setEdittedLinkReq(newLink);
  };

  const updateLinkAutoDelete = (value: boolean) => {
    const newLink = deepcopy(edittedLinkReq);
    newLink.AutoDelete = value;
    setEdittedLinkReq(newLink);
  };

  /*========================================================================================================================*/

  return (
    <Modal closeOnOverlayClick={false} isOpen={status} onClose={closeModal} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        {link ? <ModalHeader>Edit Link</ModalHeader> : <ModalHeader>Create New Link</ModalHeader>}
        <ModalCloseButton />
        <ModalBody pb={6} justifyContent={"left"}>
          <VStack align={"center"}>
            <Flex width={"100%"} justify={"left"} align={"center"}>
              <Text flexShrink={0} width={"150px"}>
                경로 설정
              </Text>
              <InputGroup>
                <InputLeftAddon children={LINK.PREFIX} />
                <Input
                  placeholder="type your link"
                  value={edittedLinkReq.TypeURL}
                  onChange={(e) => updateLinkUrl(e.target.value)}
                />
                {edittedLinkReq.TypeURL !== "" && !nameCheck(edittedLinkReq.TypeURL) && (
                  <InputRightElement w={"fit-content"} pr={"3"}>
                    <Text color={"red"}>{'특수문자 사용금지 ( % / : * ? " < > | ) '}</Text>
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
            <Flex width={"100%"} justify={"left"} align={"center"}>
              <Text flexShrink={0} width={"150px"}>
                다운로드 횟수 제한
              </Text>
              <NumberInput
                min={1}
                max={10}
                keepWithinRange={false}
                clampValueOnBlur={false}
                allowMouseWheel={true}
                onChange={(_, num) => updateLinkCount(num)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text color={"gray"} size={"sm"} ml={"10px"}>
                (최솟(기본)값 1 / 최댓값 10)
              </Text>
            </Flex>
            <Flex width={"100%"} justify={"left"} align={"center"}>
              <Text flexShrink={0} width={"150px"}>
                유효기간 지정
              </Text>
              <Input
                placeholder={"Select Date and Time"}
                type={"date"}
                min={minDate}
                max={maxDate}
                value={edittedLinkReq.ExpireDate || new Date().toDateString()}
                onChange={(e) => updateLinkExpireDate(e.target.value)}
              />
            </Flex>
            <Text color={"gray"} size={"sm"} ml={"10px"}>
              오늘로부터 최대 한달까지 선택 가능
            </Text>
            <Flex width={"100%"} justify={"left"} align={"center"}>
              <Text flexShrink={0} width={"150px"}>
                Link 자동 삭제 설정
              </Text>
              <Checkbox onChange={(e) => updateLinkAutoDelete(e.target.checked)} />
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => requestLinkUpdate()} colorScheme={"blue"} mr={3}>
            Apply
          </Button>
          <Button onClick={closeModal} colorScheme={"red"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateModal;
