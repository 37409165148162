import {
  Box,
  Center,
  TableContainer,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Heading,
  useInterval,
  TableCaption,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import FilterSection from "components/status/Filter";
import Stat from "components/status/Stat";

import { useGroup } from "hooks/useGroup";
import axios, { isAxiosError } from "axios";
import { STATUS } from "config/config";
import FileName from "components/status/FileName";

const StatusSection = ({ size = "page" }: { size?: string }): React.ReactElement => {
  const toast = useToast();
  const { selected: group } = useGroup();

  const [timer, setTimer] = useState<number>(5);

  const [filter, setFilter] = useState<Filter>({
    jobID: "",
    filename: "",
    settings: [],
    status: "All",
    beginDate: "",
    endDate: "",
  });

  const [statusList, setStatusList] = useState<Status[]>([]);
  const [displayList, setDisplayList] = useState<Status[]>([]);

  const compareDates = (d1: string, d2: string) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
      return true;
    } else if (date1 > date2) {
      return false;
    } else {
      return true;
    }
  };

  const adjustFilter = (f: Filter, list: Status[]) => {
    const idFilter = new RegExp(`${f.jobID === "" ? "^(.|\n)*?$" : f.jobID}`);
    const nameFilter = new RegExp(`${f.filename === "" ? "^(.|\n)*?$" : f.filename}`, "i");

    const newList = list
      .sort((a, b) => {
        return (new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()) * -1;
      })
      .filter((value) => idFilter.test(value.StatusId.toString()))
      .filter((value) => nameFilter.test(value.FileName))
      .filter((value) => {
        if (f.status === "All") {
          return true;
        }
        return f.status === value.Status;
      })
      .filter((value) => {
        if (f.beginDate !== "") return compareDates(f.beginDate, value.StartDate);
        else return true;
      })
      .filter((value) => {
        if (f.endDate !== "") return !compareDates(f.endDate, value.StartDate);
        else return true;
      });

    setFilter(f);
    setDisplayList(newList);
  };

  const updateStatusList = async () => {
    try {
      if (group === "") return;
      const resp = await axios.get(`${STATUS.SEARCH_LIST}/${encodeURIComponent(group)}`);

      const slist: Status[] = resp.data.GroupStatus.sort((a: Status, b: Status) => {
        return (new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()) * -1;
      });

      setStatusList(slist);
      adjustFilter(filter, slist);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "작업 목록 조회 실패",
          description: err.response?.data.message,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "작업 목록 조회 실패",
          description: `${err}`,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    updateStatusList();
  }, [group]);

  useInterval(() => {
    if (timer === 1) {
      updateStatusList();
      setTimer(5);
    } else {
      setTimer(timer - 1);
    }
  }, 1000);

  return (
    <Box>
      {size === "page" && (
        <>
          <Center m={"10px"}>
            <Heading size={"md"}>Status</Heading>
          </Center>
          <Center>
            <FilterSection filter={filter} onChange={(f) => adjustFilter(f, statusList)} />
          </Center>
        </>
      )}
      <Center w={"100%"} mt={"15px"}>
        <TableContainer width={"80%"}>
          <Table>
            <TableCaption>
              <Center>
                <Spinner thickness="1px" speed="1s" emptyColor="gray.200" color="blue.500" size="md" mr={"5px"} />
                {timer}초 후 자동 새로고침...
              </Center>
            </TableCaption>
            <Thead>
              <Tr>
                <Th width={"100px"}>
                  <Center>Status ID</Center>
                </Th>
                <Th>
                  <Center>File name</Center>
                </Th>
                <Th width={"150px"}>
                  <Center>Date</Center>
                </Th>
                <Th width={"100px"}>
                  <Center>Status</Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {displayList.length === 0 && (
                <Tr>
                  <Td colSpan={6}>
                    <Center>No Status</Center>
                  </Td>
                </Tr>
              )}
              {displayList.map((value, key) => (
                <Tr key={key}>
                  <Td width={"100px"}>{value.StatusId}</Td>
                  <Td width={"350px"}>
                    <FileName filename={value.FileName} setting={value.TargetName} />
                  </Td>
                  <Td width={"150px"}>{new Date(value.StartDate).toLocaleDateString()}</Td>
                  <Td width={"100px"}>
                    <Center>
                      <Stat status={value.Status} statusID={value.StatusId}></Stat>
                    </Center>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </Box>
  );
};

export default StatusSection;
