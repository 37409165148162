import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import axios, { isAxiosError } from "axios";
import { GROUP } from "config/config";
import React, { useEffect, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  callback: Function;
}

const GroupDeleteModal = ({ isOpen, onClose, callback }: ModalProps): React.ReactElement => {
  const toast = useToast();

  const [selected, setSelected] = useState<string>("");
  const [groupList, setGroupList] = useState<string[]>([]);

  const getGroupList = async () => {
    try {
      const resp = await axios.get(GROUP.SEARCH_LIST);

      console.log(resp.data.Groups);

      setGroupList(resp.data.Groups);
    } catch (err) {
      onClose();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;

        toast({
          title: "그룹 조회 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "그룹 조회 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      getGroupList();
    }
  }, [isOpen]);

  const request = async () => {
    try {
      const _ = await axios.delete(GROUP.DELETE, { data: { GroupName: selected } });
      onClose();
      toast({
        title: "그룹 삭제 성공",
        status: "success",
        isClosable: true,
        duration: 10000,
      });

      callback();
    } catch (err) {
      onClose();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;

        toast({
          title: "그룹 삭제 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "그룹 삭제 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size={"md"}>{"Delete Group"}</Heading>
        </ModalHeader>
        <ModalBody>
          <RadioGroup onChange={(e) => setSelected(e)}>
            <Stack>
              {groupList.map((val, idx) => (
                <Radio key={idx} value={val}>
                  {val}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => request()} colorScheme={"blue"} mr={3}>
            Apply
          </Button>
          <Button onClick={() => onClose()} colorScheme={"red"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupDeleteModal;
