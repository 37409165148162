export interface ModalProps {
  modalType: "Create" | "Edit";
  group: string;
  status: boolean;
  onClose: () => void;
  setting: Setting;
}

export const EmptySetting: Setting = {
  ID: 0,
  CreatedAt: "",
  UpdatedAt: "",
  DeletedAt: null,

  Name: "EmptySetting",
  GroupId: 123,
  TargetType: "image",

  EmbedImageRefer: 0,
  EmbedAudioRefer: 0,
  EmbedVideoRefer: 0,
  EmbedImage: {
    ID: 1,
    CreatedAt: "",
    UpdatedAt: "",
    DeletedAt: "",
    Invisible: false,
    VisibleText: false,
    VisibleFile: false,
  },
  EmbedAudio: {
    ID: 1,
    CreatedAt: "",
    UpdatedAt: "",
    DeletedAt: "",
    Inaudible: false,
    AudibleFile: false,
    AudibleTts: false,
  },
  EmbedVideo: {
    ID: 1,
    CreatedAt: "",
    UpdatedAt: "",
    DeletedAt: "",
    Invisible: false,

    VisibleText: false,
    VisibleFile: false,
    Inaudible: false,
    AudibleFile: false,
    AudibleTts: false,
  },
};
