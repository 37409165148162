import React, { useEffect, useState } from "react";

import EditModal from "components/users/UserEditModal";

import {
  Heading,
  Card,
  CardHeader,
  CardBody,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Center,
  Td,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  useToast,
  Tfoot,
  Flex,
  Text,
  Spacer,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import axios, { isAxiosError } from "axios";
import { USER } from "config/config";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiPlayListAddLine } from "react-icons/ri";
import { useGroup } from "hooks/useGroup";
import { useNavigate } from "react-router-dom";
import GroupUpdateModal from "components/users/GroupUpdateModal";
import GroupDeleteModal from "components/users/GroupDeleteModal";

const Users = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen: groupCreateModalStatus, onOpen: groupCreateModalOpen, onClose: groupCreateModalClose } = useDisclosure();
  const { isOpen: groupDeleteModalStatus, onOpen: groupDeleteModalOpen, onClose: groupDeleteModalClose } = useDisclosure();

  const { selected: group } = useGroup();
  const [users, setUsers] = useState<User[]>([]);

  const updateUsers = async () => {
    try {
      const resp = await axios.get(USER.SEARCH_LIST);

      const userList: User[] = resp.data.Users;

      for (let idx = 0; idx < userList.length; idx++) {
        if (userList[idx].Groups.includes("Admin")) {
          userList[idx] = { ...userList[idx], authority: true };
        }
      }

      setUsers(userList);

      toast({
        title: "사용자 목록 조회 성공",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "사용자 목록 조회 실패",
          description: err.response?.data.message,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "사용자 목록 조회 실패",
          description: `${err}`,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (group !== "Admin") {
      navigate("/");
      return;
    }
    updateUsers();
  }, [group]);

  return (
    <Center>
      <Card>
        <CardHeader>
          <Heading size={"lg"}>Users</Heading>

          <Flex width={"100%"}>
            <Heading size={"md"}>Watermark Setting</Heading>
            <Spacer />
            <Spacer />
            <Center>
              <Button
                marginRight={"20px"}
                aria-label={"add group"}
                leftIcon={<RiPlayListAddLine />}
                variant={"outline"}
                colorScheme={"teal"}
                onClick={groupCreateModalOpen}
              >
                Group Add
              </Button>
              <Button
                aria-label={"delete group"}
                leftIcon={<RiPlayListAddLine />}
                variant={"outline"}
                colorScheme={"red"}
                onClick={groupDeleteModalOpen}
              >
                Group Delete
              </Button>
              <GroupUpdateModal isOpen={groupCreateModalStatus} onClose={groupCreateModalClose} />
              <GroupDeleteModal isOpen={groupDeleteModalStatus} onClose={groupDeleteModalClose} callback={updateUsers} />
            </Center>
          </Flex>
        </CardHeader>
        <CardBody>
          <TableContainer width={"1200px"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Center>User ID</Center>
                  </Th>
                  <Th>
                    <Center>Groups</Center>
                  </Th>
                  <Th>
                    <Center>Interface</Center>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((value, key) => (
                  <Tr key={key}>
                    <Td color={value.authority ? "coral" : "WindowText"}>
                      <Center>{value.UserName}</Center>
                    </Td>
                    <Td>
                      <Wrap>
                        {value.Groups.map((group, key) => (
                          <WrapItem key={key}>
                            <Tag>
                              <TagLabel>{group}</TagLabel>
                            </Tag>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </Td>
                    <Td>
                      <EditModal user={value} callback={updateUsers} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
    </Center>
  );
};

export default Users;
