export const STATUS = ["Request", "Embedding", "Transcoding", "Complete", "Error"];

export const EmptyStatus: Status = {
  ID: 0,
  CreatedAt: "",
  UpdatedAt: "",
  DeletedAt: "",
  StatusId: 0,
  Status: "Request",
  Error: "",
  StartDate: "",
  FileName: "",
  TargetName: "",
};
