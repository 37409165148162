import React from "react";
import { Link } from "react-router-dom";

import Status from "pages/Status";

import { Card, CardBody, Box, Heading, Wrap, WrapItem, CardHeader, CardFooter, Button } from "@chakra-ui/react";

import { TbSettingsShare, TbFileUpload, TbExternalLink } from "react-icons/tb";

const Main = (): React.ReactElement => {
  return (
    <Box zIndex={"-1"}>
      <Wrap align={"center"} justify={"center"}>
        <WrapItem>
          <Card>
            <CardHeader>
              <Heading size={"md"}>Job Status</Heading>
            </CardHeader>
            <CardBody minWidth={"1200px"}>
              <Box height={"70vh"} minHeight={"500px"} overflow={"scroll"}>
                <Status size={"card"}></Status>
              </Box>
            </CardBody>
            <CardFooter
              justify="space-between"
              flexWrap="wrap"
              sx={{
                "& > button": {
                  minW: "136px",
                },
              }}
            >
              <Button flex="1" variant="ghost" leftIcon={<TbSettingsShare />} as={Link} to={"/settings"}>
                Settings
              </Button>
              <Button flex="1" variant="ghost" leftIcon={<TbFileUpload />} as={Link} to={"/upload"}>
                Upload
              </Button>
              <Button flex="1" variant="ghost" leftIcon={<TbExternalLink />} as={Link} to={"/status"}>
                Status
              </Button>
            </CardFooter>
          </Card>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default Main;
