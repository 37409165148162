import {
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios, { isAxiosError } from "axios";
import { GROUP } from "config/config";
import React, { useEffect, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const nameCheck = (name: string): boolean => {
  var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  var rg2 = /^\./; // cannot start with dot (.)
  var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  var rg4 = /%/;

  return rg1.test(name) && !rg2.test(name) && !rg3.test(name) && !rg4.test(name);
};

const GroupUpdateModal = ({ isOpen, onClose }: ModalProps): React.ReactElement => {
  const toast = useToast();
  const [groupName, setGroupName] = useState<string>("");

  useEffect(() => {
    if (isOpen) {
      setGroupName("");
    }
  }, [isOpen]);

  const request = async () => {
    try {
      if (!nameCheck(groupName)) {
        throw new Error('잘못된 그룹 이름입니다.\n % / : * ? " < > | 가 포함되었거나 공백은 사용이 불가능합니다.');
      }

      const _ = await axios.post(GROUP.CREATE, { GroupName: groupName });
      onClose();
      toast({
        title: "그룹 생성 성공",
        status: "success",
        isClosable: true,
        duration: 10000,
      });
    } catch (err) {
      onClose();

      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;

        toast({
          title: "그룹 생성 실패",
          description: description,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      } else {
        toast({
          title: "그룹 생성 실패",
          description: `${err}`,
          status: "error",
          isClosable: true,
          duration: 10000,
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size={"md"}>{"Create New Group"}</Heading>
        </ModalHeader>
        <ModalBody>
          <InputGroup>
            <Input
              placeholder={"Type Group Name..."}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            ></Input>
            {!nameCheck(groupName) && (
              <InputRightElement w={"fit-content"} pr={"3"}>
                <Text color={"red"}>{'특수문자 사용금지 ( % / : * ? " < > | ) '}</Text>
              </InputRightElement>
            )}
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => request()} colorScheme={"blue"} mr={3}>
            Apply
          </Button>
          <Button onClick={() => onClose()} colorScheme={"red"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupUpdateModal;
