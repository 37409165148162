import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { useGroup } from "hooks/useGroup";

import MenuTab from "./MenuTab";

import { HStack, Box, Center, Link as LinkUI, Spacer, Button, Text, Flex, Select } from "@chakra-ui/react";
import { BiUser, BiGroup } from "react-icons/bi";

const Header = (): React.ReactElement => {
  const { email, isAuthenticated, logout } = useAuth();
  const { groups, selected, changeGroup } = useGroup();

  return (
    <Box width={"100vw"} height={"100px"}>
      {isAuthenticated && (
        <Flex width={"100%"} height={"100%"}>
          <Box width={"500px"} height={"100%"} ml={"40px"}>
            <HStack height={"100%"} spacing={6} display={{ base: "none", md: "flex" }}>
              {selected !== "WaitGroup" && (
                <>
                  <LinkUI as={Link} to={"/"} fontSize={"lg"} fontWeight={"bold"}>
                    Home
                  </LinkUI>

                  <LinkUI as={Link} to={"/settings"} fontSize={"lg"} fontWeight={"bold"}>
                    Settings
                  </LinkUI>

                  <LinkUI as={Link} to={"/upload"} fontSize={"lg"} fontWeight={"bold"}>
                    Upload
                  </LinkUI>

                  <LinkUI as={Link} to={"/status"} fontSize={"lg"} fontWeight={"bold"}>
                    Status
                  </LinkUI>

                  {selected === "Admin" && (
                    <LinkUI as={Link} to={"/users"} fontSize={"lg"} fontWeight={"bold"}>
                      Users
                    </LinkUI>
                  )}
                </>
              )}
            </HStack>
            <MenuTab />
          </Box>
          <Spacer />
          <HStack spacing={6} p={"10px"} height={"100px"} mr={"15px"}>
            <BiGroup size={"30px"} />
            <Center>
              <Select value={selected} onChange={(e) => changeGroup(e.target.value)}>
                {groups !== null &&
                  groups.map((value, key) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
              </Select>
            </Center>
            <BiUser size={"30px"} />
            <Center>
              <Text as={"b"} fontSize={"lg"}>
                {email}
              </Text>
            </Center>
            <Center>
              <Button
                onClick={() => {
                  logout();
                  changeGroup("");
                }}
              >
                Logout
              </Button>
            </Center>
          </HStack>
        </Flex>
      )}
    </Box>
  );
};

export default Header;
