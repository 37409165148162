import React, { createContext, useMemo, useContext, useEffect, useState } from "react";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { googleLogout } from "@react-oauth/google";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { GROUP } from "config/config";
import { useNavigate } from "react-router-dom";

interface Token extends JwtPayload {
  email: string;
  name: string;
}
interface Auth {
  isAuthenticated: boolean;
  username: string;
  email: string;
  // groups: string[];

  googleLogin: (credential: string) => Promise<void>;
  login: () => void;
  logout: () => void;
  // refresh: () => void;
}

export const AuthContext = createContext<Auth>({
  isAuthenticated: false,
  username: "",
  email: "",
  // groups: [],

  googleLogin: async (credential: string) => {},
  login: () => {},
  logout: () => {},

  // refresh: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement => {
  const toast = useToast();

  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  const googleLogin = async (credential: string) => {
    try {
      const decoded = jwtDecode<Token>(credential);

      setEmail(decoded.email);
      setUsername(decoded.name);

      toast({
        title: "Success",
        description: "구글 로그인 완료",
        status: "success",
        isClosable: true,
        duration: 10000,
      });
    } catch (err) {
      toast({
        title: "Failed to login",
        description: "구글 로그인 실패" + err,
        status: "error",
        isClosable: true,
        duration: 10000,
      });
    }
  };

  const login = () => {
    setAuthenticated(true);
  };

  const logout = () => {
    googleLogout();
    setAuthenticated(false);
  };

  const value = useMemo(
    () => ({
      email,
      username,
      isAuthenticated,
      googleLogin,
      login,
      logout,
    }),
    [email, isAuthenticated],
  );

  useEffect(() => {
    console.log("call mount");

    return () => {
      console.log("call unmount");
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): Auth => {
  const ctx = useContext(AuthContext);
  return ctx;
};
