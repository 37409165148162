import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Heading,
  Card,
  CardBody,
  CardHeader,
  VStack,
  Button,
  useDisclosure,
  Center,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { EmptyStatus } from "model/status";
import { EmptySetting } from "model/setting";

import StatusStat from "components/info/Stat/StatusStat";
import SettingStat from "components/info/Stat/SettingStat";
import LinkStat from "components/info/Stat/LinkStat";

import UpdateModal from "components/info/Modal/LinkUpdateModal";

import axios, { isAxiosError } from "axios";
import { LINK, SETTING, STATUS } from "config/config";
import { useGroup } from "hooks/useGroup";
import DeleteModal from "components/info/Modal/LinkDeleteModal";

const Info = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen: CreateLinkModalStatus, onOpen: CreateLinkModalOnOpen, onClose: CreateLinkModalOnclose } = useDisclosure();
  const { isOpen: EditLinkModalStatus, onOpen: EditLinkModalOnOpen, onClose: EditLinkModalOnclose } = useDisclosure();
  const { isOpen: DeleteLinkModalStatus, onOpen: DeleteLinkModalOnOpen, onClose: DeleteLinkModalOnclose } = useDisclosure();

  const { id } = useParams();

  const { selected: group } = useGroup();
  const [statusGroup, setStatusGroup] = useState<string>(group);
  const [sourceFile, setSourceFile] = useState<string>("");
  const [status, setStatus] = useState<Status>(EmptyStatus);
  const [setting, setSetting] = useState<Setting>(EmptySetting);
  const [link, setLink] = useState<Link>();

  const updateLink = async (status: Status, setting: Setting) => {
    try {
      // link
      // groupName/settingName/fileName -> groupName/settingType/fileNameWithoutExt
      const fileNameWithoutExt = status.FileName.split("/").slice(-1)[0].split(".").slice(0, -1).join(".") || "";
      let fileName = "";
      switch (setting.TargetType) {
        case "audio":
          fileName = fileNameWithoutExt + ".mp3";
          break;
        case "image":
          fileName = status.FileName.split("/").pop() || "";
          break;
        case "video":
          fileName = fileNameWithoutExt + ".m3u8";
          break;
      }

      const respLink = await axios.get(
        `${LINK.SEARCH_ITEM}?S3FilePath=${encodeURIComponent(group)}/${encodeURIComponent(
          setting.Name,
        )}/${encodeURIComponent(fileNameWithoutExt)}/`,
      );

      if (respLink.data.Links === null) {
        setLink(undefined);
        setSourceFile(`${group}/${setting.Name}/${fileNameWithoutExt}/${fileName}`);
      } else {
        setLink(respLink.data.Links[0]);
        setSourceFile(respLink.data.Links[0].SourcePath);

        toast({
          title: "링크 조회 완료",
          status: "success",
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (err) {
      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;
        toast({
          title: "링크 조회 실패",
          description: description,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "링크 조회 실패",
          description: `${err}`,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const updateStatus = async () => {
    try {
      // status
      const respStatus = await axios.get(`${STATUS.SEARCH_ITEM}/${encodeURIComponent(id || "")}`);
      const stat: Status = respStatus.data;
      setStatus(stat);

      // setting
      const respSetting = await axios.get(
        `${SETTING.SEARCH_ITEM}/${encodeURIComponent(group)}/${encodeURIComponent(stat.TargetName)}`,
      );
      const set: Setting = respSetting.data.EmbedTargets[0];
      setSetting(set);

      return { stat, set };
    } catch (err) {
      if (isAxiosError(err)) {
        const description = err.response?.data.message
          ? `${err.response?.status}: ${JSON.stringify(err.response?.data.message)}`
          : `${err.cause}`;
        toast({
          title: "작업 정보 조회 실패",
          description: description,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      } else {
        toast({
          title: "작업 정보 조회 실패",
          description: `${err}`,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const refresh = async () => {
    try {
      const result = await updateStatus();

      if (result) {
        updateLink(result.stat, result.set);
      }
    } catch (err) {
      toast({
        title: "Failed to load.",
        description: "Refresh 실패... " + err,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (statusGroup !== group) {
      navigate("/status");
      return;
    }
    refresh();
  }, [id, group]);

  return (
    <VStack width={"100vw"} spacing={4}>
      <StatusStat status={status} />
      <SettingStat setting={setting} />

      {link === undefined ? (
        <Center mt={"30px"}>
          {status.Status === "Complete" && <Button onClick={() => CreateLinkModalOnOpen()}>Create Link</Button>}
        </Center>
      ) : (
        <Card minWidth={"800px"} width={"80vw"} justify={"left"} mb={"30px"}>
          <CardHeader width={"100%"}>
            <Flex>
              <Heading flexShrink={0} size={"md"} textAlign={"left"}>
                Link Info
              </Heading>
              <Spacer />
              <Button alignSelf={"right"} variant={"ghost"} onClick={() => EditLinkModalOnOpen()}>
                Edit
              </Button>
              <Button alignSelf={"right"} variant={"ghost"} colorScheme={"red"} onClick={() => DeleteLinkModalOnOpen()}>
                Delete
              </Button>
            </Flex>
            <UpdateModal
              status={EditLinkModalStatus}
              onClose={() => {
                EditLinkModalOnclose();
                updateLink(status, setting);
              }}
              link={link}
              sourceFile={link.SourcePath}
            />
            <DeleteModal
              status={DeleteLinkModalStatus}
              onClose={() => {
                DeleteLinkModalOnclose();
                updateLink(status, setting);
              }}
              link={link}
              sourceFile={link.SourcePath}
            />
          </CardHeader>
          <CardBody justifyContent={"center"}>
            <LinkStat link={link || {}}></LinkStat>
          </CardBody>
        </Card>
      )}

      <UpdateModal
        status={CreateLinkModalStatus}
        onClose={() => {
          CreateLinkModalOnclose();
          updateLink(status, setting);
        }}
        sourceFile={sourceFile}
      />
    </VStack>
  );
};

export default Info;
