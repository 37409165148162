import React from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, Center, Text } from "@chakra-ui/react";

const Notfound = (): React.ReactElement => {
  return (
    <Center width={"100vw"} height={"100vh"} position={"fixed"} top={"0"} zIndex={"-1"}>
      <Card>
        <CardBody>
          <Text>요청하신 페이지를 찾을 수 없습니다.</Text>
          <Link to={"/"}>Go to Home</Link>
        </CardBody>
      </Card>
    </Center>
  );
};

export default Notfound;
