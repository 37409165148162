import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Heading,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Flex,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";

import { STATUS } from "model/status";

const deepcopy = (obj: Filter): Filter => {
  return JSON.parse(JSON.stringify(obj));
};

const FilterSection = ({ filter, onChange }: { filter: Filter; onChange: (f: Filter) => void }): React.ReactElement => {
  const updateJobIDFilter = (value: string) => {
    let newFilter: Filter = deepcopy(filter);
    newFilter.jobID = value;
    onChange(newFilter);
  };

  const updateFilenameFilter = (value: string) => {
    let newFilter: Filter = deepcopy(filter);
    newFilter.filename = value;
    onChange(newFilter);
  };

  const updateStatus = (value: string) => {
    let newFilter: Filter = deepcopy(filter);
    newFilter.status = value as "";
    onChange(newFilter);
  };

  const updateBeginDate = (value: string) => {
    let newFilter: Filter = deepcopy(filter);
    newFilter.beginDate = value;
    onChange(newFilter);
  };

  const updateEndDate = (value: string) => {
    let newFilter: Filter = deepcopy(filter);
    newFilter.endDate = value;
    onChange(newFilter);
  };

  return (
    <Accordion allowToggle width={"80%"}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Flex as="span" flex="1" textAlign="left">
              <Heading size={"md"}>Filter</Heading>
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel p={4}>
          <VStack spacing={5} align={"flex-start"}>
            <Wrap>
              <WrapItem>
                <Heading size={"sm"} width={"130px"} textAlign={"left"}>
                  Job ID
                </Heading>
              </WrapItem>
              <WrapItem>
                <Input
                  placeholder={"type target job id..."}
                  type={"text"}
                  value={filter.jobID}
                  onChange={(e) => {
                    updateJobIDFilter(e.target.value);
                  }}
                />
              </WrapItem>
            </Wrap>
            <Wrap>
              <WrapItem>
                <Heading size={"sm"} width={"130px"} textAlign={"left"}>
                  File Name
                </Heading>
              </WrapItem>
              <WrapItem>
                <Input
                  placeholder={"type target file name..."}
                  type={"text"}
                  value={filter.filename}
                  onChange={(e) => {
                    updateFilenameFilter(e.target.value);
                  }}
                />
              </WrapItem>
            </Wrap>

            <Wrap>
              <WrapItem>
                <Heading size={"sm"} width={"130px"} textAlign={"left"}>
                  Status
                </Heading>
              </WrapItem>
              <WrapItem>
                <RadioGroup onChange={(e) => updateStatus(e)} value={filter.status}>
                  <Stack direction="row">
                    <Radio value={"All"}>All</Radio>
                    {STATUS.map((value, key) => (
                      <Radio key={key} value={value}>
                        {value}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </WrapItem>
            </Wrap>
            <Wrap align={"center"}>
              <WrapItem>
                <Heading size={"sm"} width={"130px"} textAlign={"left"}>
                  Date
                </Heading>
              </WrapItem>
              <WrapItem>
                <Input type={"date"} value={filter.beginDate} onChange={(e) => updateBeginDate(e.target.value)}></Input>
                <Text> ~ </Text>
                <Input type={"date"} value={filter.endDate} onChange={(e) => updateEndDate(e.target.value)}></Input>
              </WrapItem>
              <WrapItem></WrapItem>
            </Wrap>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FilterSection;
