export const EmptyLink: Link = {
  LinkSeq: 0,
  Link: "",
  SourcePath: "",
  Expire: undefined,
  Count: -1,
  AutoDelete: false,
};

export const EmptyLinkReq: LinkReq = {
  AccessToken: "",
  BucketName: "",
  S3FilePath: "",
  TypeURL: "",
  ExpireCount: 0,
  ExpireDate: null,
  AutoDelete: false,
};
