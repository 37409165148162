import React, { useEffect } from "react";

import { Center, Card, CardBody, Heading, VStack } from "@chakra-ui/react";

import { useAuth } from "hooks/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navi = useNavigate();
  const { googleLogin, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navi("/");
    }
  }, [isAuthenticated]);

  return (
    <Center position={"fixed"} top={0} left={0} width={"100vw"} height={"100vh"}>
      <Card width={"600px"} height={"400px"}>
        <CardBody>
          <Center width={"100%"} height={"100%"}>
            <VStack>
              <Heading>Hybe Watermark System</Heading>
              <GoogleLogin
                onSuccess={async (res) => {
                  try {
                    const _ = await googleLogin(res.credential || "");
                  } catch {}
                }}
              />
            </VStack>
          </Center>
        </CardBody>
      </Card>
    </Center>
  );
};
export default Login;
