import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/common/Header";
import Private from "components/common/Private";

import Main from "./pages/Main";
import Settings from "./pages/Settings";
import Upload from "./pages/Upload";
import Status from "./pages/Status";
import Info from "./pages/Info";
import Notfound from "./pages/Notfound";
import Login from "pages/Login";
import Users from "pages/Users";

import { Center, Heading } from "@chakra-ui/react";
import { useGroup } from "hooks/useGroup";

function App() {
  const { selected: group } = useGroup();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
      return (event.returnValue = "");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        {group !== "WaitGroup" ? (
          <Routes>
            <Route
              path="/"
              element={
                <Private>
                  <Main />
                </Private>
              }
            />
            <Route
              path="/settings"
              element={
                <Private>
                  <Settings />
                </Private>
              }
            />
            <Route
              path="/upload"
              element={
                <Private>
                  <Upload />
                </Private>
              }
            />
            <Route
              path="/status"
              element={
                <Private>
                  <Status />
                </Private>
              }
            />
            <Route
              path="/info/:id"
              element={
                <Private>
                  <Info />
                </Private>
              }
            />
            <Route
              path="/users"
              element={
                <Private>
                  <Users />
                </Private>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Notfound />} />
          </Routes>
        ) : (
          <Center position={"fixed"} width={"100vw"} top={"50%"}>
            <Heading>그룹을 변경하거나 관리자에게 그룹 할당을 요청하세요.</Heading>
          </Center>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
