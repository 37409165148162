import React from "react";
import { Link } from "react-router-dom";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Heading,
  Center,
  VStack,
  IconButton,
  useColorModeValue,
  Link as LinkUI,
  Box,
} from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import { useGroup } from "hooks/useGroup";

const MenuTab = (): React.ReactElement => {
  const { selected } = useGroup();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display={{ base: "flex", md: "none" }} height={"100%"}>
      <Center>
        <IconButton aria-label={"Menu"} icon={<HamburgerIcon />} onClick={onOpen}>
          Open
        </IconButton>
      </Center>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent backgroundColor={useColorModeValue("#ffffff", "#202023")}>
          <DrawerCloseButton />
          <DrawerHeader>
            <Center>
              <Heading fontSize={"xl"}>Menu</Heading>
            </Center>
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={6}>
              {selected !== "WaitGroup" && (
                <>
                  <LinkUI as={Link} to={"/"} fontSize={"lg"} fontWeight={"bold"}>
                    Home
                  </LinkUI>

                  <LinkUI as={Link} to={"/settings"} fontSize={"lg"} fontWeight={"bold"}>
                    Settings
                  </LinkUI>

                  <LinkUI as={Link} to={"/upload"} fontSize={"lg"} fontWeight={"bold"}>
                    Upload
                  </LinkUI>

                  <LinkUI as={Link} to={"/status"} fontSize={"lg"} fontWeight={"bold"}>
                    Status
                  </LinkUI>

                  {selected === "Admin" && (
                    <LinkUI as={Link} to={"/users"} fontSize={"lg"} fontWeight={"bold"}>
                      Users
                    </LinkUI>
                  )}
                </>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MenuTab;
