import React from "react";

import { Center, Flex, Highlight, Text } from "@chakra-ui/react";

const Memo = ({ group, setting, files }: UploadForm): React.ReactElement => {
  return (
    <Flex
      justifyContent={"left"}
      width={"70vw"}
      height={"100px"}
      border={"2px"}
      borderRadius={"lg"}
      borderColor={"gray.100"}
      padding={"20px"}
    >
      {group !== "" && (
        <Center>
          <Text size={"md"} as={"b"}>
            <Highlight query={`${group}`} styles={{ px: "2", py: "1", bg: "teal.100" }}>
              {`Group: ${group}`}
            </Highlight>
          </Text>
        </Center>
      )}
      {setting !== undefined && (
        <Center ml={"5px"}>
          <Text size={"md"} as={"b"}>
            <Highlight query={`${setting.Name} - ${setting.TargetType}`} styles={{ px: "2", py: "1", bg: "teal.100" }}>
              {`> Setting: ${setting.Name} - ${setting.TargetType}`}
            </Highlight>
          </Text>
        </Center>
      )}
      {files !== undefined && (
        <Center ml={"5px"}>
          <Text size={"md"} as={"b"}>
            <Highlight query={`${files.length}file(s).`} styles={{ px: "2", py: "1", bg: "teal.100" }}>
              {` > Files: ${files.length}file(s).`}
            </Highlight>
          </Text>
        </Center>
      )}
    </Flex>
  );
};

export default Memo;
