import React, { useEffect, useState } from "react";

import {
  Flex,
  Center,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Text,
  Tooltip,
  Tfoot,
  useDisclosure,
  Checkbox,
} from "@chakra-ui/react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import UploadModal from "../Modal/UploadModal";

const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const UploadStage = ({ form }: { form: UploadForm }): React.ReactElement => {
  const { isOpen: uploadModalStatus, onOpen: uploadModalOpen, onClose: uploadModalClose } = useDisclosure();

  const [uploadList, setUploadList] = useState<File[]>([]);
  const [uploadQuality, setUploadQuality] = useState<Map<string, boolean[]>>(new Map<string, boolean[]>());

  const updateUploadQuailty = (filename: string, quality: number, checked: boolean) => {
    const newMap = new Map<string, boolean[]>(uploadQuality);
    const downloadModes = uploadQuality.get(filename) || [false, false, false];
    downloadModes[quality] = checked;
    newMap.set(filename, downloadModes);
    setUploadQuality(newMap);
  };

  useEffect(() => {
    const quality = new Map<string, boolean[]>();
    form.files?.map((val, _) => {
      quality.set(val.name, [false, false, false]);
    });
    setUploadQuality(quality);
  }, [form]);

  useEffect(() => {
    if (uploadList.length > 0) {
      uploadModalOpen();
    }
  }, [uploadList]);

  return (
    <Center width={"100%"} height={"100%"}>
      <VStack width={"70%"} height={"100%"}>
        <Center>
          <Text>3. 파일을 업로드 합니다.</Text>
        </Center>
        <Box width={"100%"} height={"450px"} overflowY={"scroll"}>
          <TableContainer width={"100%"}>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Center>File Name</Center>
                  </Th>
                  <Th>
                    <Center>File Size</Center>
                  </Th>
                  <Th>
                    <Center>Download Modes</Center>
                  </Th>
                  <Th>
                    <Center>Upload</Center>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {form.files?.map((value, key) => (
                  <Tr key={key}>
                    <Td>
                      <Tooltip label={value.name}>
                        <Text textOverflow={"ellipsis"} overflow={"hidden"} maxWidth={"40%"}>
                          {value.name}
                        </Text>
                      </Tooltip>
                    </Td>
                    <Td width={"150px"}>
                      <Center>{formatBytes(value.size, 3)}</Center>
                    </Td>
                    <Td>
                      <VStack pl={6} mt={1} spacing={1} align={"left"}>
                        <Checkbox
                          isDisabled={form.setting?.TargetType === "image"}
                          isChecked={uploadQuality.get(value.name)?.[0] || false}
                          onChange={(e) => updateUploadQuailty(value.name, 0, e.target.checked)}
                        >
                          High Quailty
                        </Checkbox>
                        <Checkbox
                          isDisabled={form.setting?.TargetType === "image"}
                          isChecked={uploadQuality.get(value.name)?.[1] || false}
                          onChange={(e) => updateUploadQuailty(value.name, 1, e.target.checked)}
                        >
                          Standard Quailty
                        </Checkbox>
                        <Checkbox
                          isDisabled={form.setting?.TargetType === "image"}
                          isChecked={uploadQuality.get(value.name)?.[2] || false}
                          onChange={(e) => updateUploadQuailty(value.name, 2, e.target.checked)}
                        >
                          Low Quailty
                        </Checkbox>
                      </VStack>
                    </Td>
                    <Td width={"150px"}>
                      <Center>
                        <Button
                          colorScheme={"green"}
                          onClick={() => {
                            setUploadList([value]);
                          }}
                        >
                          Upload
                        </Button>
                      </Center>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Td colSpan={4}>
                    <Center>
                      <Button
                        colorScheme={"green"}
                        onClick={() => {
                          setUploadList(form.files ? [...form.files] : []);
                        }}
                      >
                        Upload at once
                      </Button>
                    </Center>
                  </Td>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
        <Center>
          <Flex align={"center"} color={"gray"}>
            <AiOutlineInfoCircle />
            <Text size={"sm"} ml={"5px"}>
              파일의 크기에 따라 업로드에 시간이 소요될 수 있습니다.
            </Text>
          </Flex>
        </Center>
      </VStack>
      <UploadModal
        status={uploadModalStatus}
        onClose={uploadModalClose}
        files={uploadList}
        setting={form.setting?.Name || ""}
        qualities={uploadQuality}
      />
    </Center>
  );
};

export default UploadStage;
