import React, { useState, ChangeEvent } from "react";

import {
  Box,
  Center,
  Text,
  useToast,
  VStack,
  Divider,
  Wrap,
  WrapItem,
  ButtonGroup,
  Button,
  IconButton,
} from "@chakra-ui/react";

import Dropzone from "components/common/Dropzone";
import { DeleteIcon } from "@chakra-ui/icons";

const FileStage = ({ fileType, onChange }: { fileType: string; onChange: (g: File[]) => void }): React.ReactElement => {
  const [files, setFiles] = useState<File[]>([]);

  const toast = useToast();

  const updateFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    let fileArray: File[] = Array.from(e.target.files);

    let correctFiles = fileArray.filter((file, idx, origin) => {
      if (file.type.startsWith(fileType.toLowerCase())) {
        return true;
      } else {
        toast({
          title: "File type error",
          description: `파일 형식이 잘못되었습니다. (${file.name}은 ${fileType}가 아닙니다.)`,
          status: "error",
          isClosable: true,
        });
        return false;
      }
    });

    onChange(correctFiles);
    setFiles(correctFiles);
  };

  const deleteFile = (key: string) => {
    let fileArray: File[] = files.filter((value) => value.name !== key);

    setFiles(fileArray);
    onChange(fileArray);
  };

  return (
    <Box width={"100%"} height={"100%"}>
      <Center width={"100%"}>
        <VStack width={"70%"}>
          <Text>2. 업로드할 파일을 선택합니다. (복수 선택 가능)</Text>
          <Dropzone multiple={true} width={"100%"} onChange={(e: ChangeEvent<HTMLInputElement>) => updateFiles(e)} />
          <Divider />
          <Wrap spacing={"10px"} justify={"center"}>
            {files.map((value, key) => (
              <WrapItem key={key}>
                <Center>
                  <ButtonGroup size="sm" isAttached variant="outline">
                    <Button _hover={undefined}>{value.name}</Button>
                    <IconButton
                      aria-label="Delete files in list"
                      icon={<DeleteIcon />}
                      onClick={() => deleteFile(value.name)}
                    />
                  </ButtonGroup>
                </Center>
              </WrapItem>
            ))}
          </Wrap>
        </VStack>
      </Center>
    </Box>
  );
};

export default FileStage;
