import React from "react";

import Item from "components/settings/Item";

import { Center, TableContainer, Table, Thead, Tr, Th, Tbody } from "@chakra-ui/react";

interface TableProps {
  settings: Setting[];
  callback: () => {};
}

const SettingTable = ({ settings, callback }: TableProps): React.ReactElement => {
  return (
    <TableContainer width={"70vw"}>
      <Table variant={"simple"}>
        <Thead>
          <Tr>
            <Th>
              <Center>Name</Center>
            </Th>
            <Th width={"150px"}>
              <Center>Type</Center>
            </Th>
            <Th width={"400px"}>
              <Center>Interface</Center>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {settings
            .sort((l, r) => {
              if (Date.parse(l.CreatedAt) > Date.parse(r.CreatedAt)) return -1;
              if (Date.parse(l.CreatedAt) < Date.parse(r.CreatedAt)) return 1;
              return 0;
            })
            .map((value, key) => {
              return <Item setting={value} key={key} callback={callback}></Item>;
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SettingTable;
