export const OAUTH_CLIENT_ID = "103842964323-8veolqnfbi8hslafio95ge3l0gvn4f13.apps.googleusercontent.com";

export const BASEURL = "https://newhybe.mediasafer.com";
export const HOST = "https://newhybe.mediasafer.com/admin";

export const SETTING = {
  CREATE: `${HOST}/setting`,
  UPDATE: `${HOST}/setting`,
  DELETE: `${HOST}/setting`,
  SEARCH_LIST: `${HOST}/setting`,
  SEARCH_ITEM: `${HOST}/setting`,
};

export const PREURL = {
  CREATE: `${HOST}/watermark/url`,
};

export const UPLOAD = {
  CREATE: `${HOST}/watermark`,
};

export const USER = {
  UPDATE: `${HOST}/user/update`,
  SEARCH_LIST: `${HOST}/user`,
};

export const GROUP = {
  CREATE: `${HOST}/group`,
  DELETE: `${HOST}/group`,
  SEARCH_LIST: `${HOST}/group`,
  SEARCH_LIST_USER: `${HOST}/user/group`,
};

export const STATUS = {
  SEARCH_LIST: `${HOST}/watermark/status`,
  SEARCH_ITEM: `${HOST}/watermark/status/group`,
};

export const LINK = {
  PREFIX: `${BASEURL}/link/`,
  BUCKET: "newhybe-streaming-bucket",
  CREATE: `${HOST}/link`,
  DLELTE: `${HOST}/link`,
  SEARCH_ITEM: `${HOST}/link`,
};
